import React, { useState } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { renderDifferentActions } from './MapEditorHelpers'
import { handleZoomBtns } from './helpers'
import EyeShow from 'layout-components/Eye/EyeShow'

export const MiniMapButtons = ({
    areaUuid,
    toggle,
    sizes,
    setSize,
    icon,
    setIcon,
    hidePaths,
    setHidePaths,
    vehicle,
    sidebar,
    stageRef,
    showTagZonesNames,
    setShowTagZonesNames,
    showVehiclesNames,
    setShowVehiclesNames,
    setEstimatedPose,
}) => {
    const [buttonState, setButtonState] = useState({
        isEyeHovered: false,
        zoomIsHover: false,
        zoomIsClicked: false,
        zoomOutIsHover: false,
        zoomOutIsClicked: false,
        saveIsHover: false,
        saveIsClicked: false,
        estPoseIsHover: false,
        estPoseIsClicked: false,
    })
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)

    const handleButtonState = ({ button, value }) => {
        setButtonState({ ...buttonState, [button]: value })
    }

    const handleButtonEvent = ({ button, value }) => {
        return () => {
            handleButtonState({ button, value })
        }
    }

    const buttonClass = 'p-0 iconBoader'

    return (
        <div
            className="d-flex flex-column mt-3"
            style={{ zIndex: 1000, position: 'fixed', right: sidebar ? '27rem' : '2rem' }}
        >
            <Button
                size="sm"
                id="save-btn"
                className="p-0 iconBoader my-2"
                onClick={toggle}
                onMouseDown={handleButtonEvent({ button: 'saveIsClicked', value: true })}
                onMouseUp={handleButtonEvent({ button: 'saveIsClicked', value: false })}
                onMouseEnter={handleButtonEvent({ button: 'saveIsHover', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'saveIsHover', value: false })}
            >
                {renderDifferentActions(
                    buttonState.saveIsHover,
                    '/svgs/map-editor/save-hover.svg',
                    buttonState.saveIsClicked,
                    '/svgs/map-editor/save-clicked.svg',
                    '/svgs/map-editor/save.svg',
                    'save'
                )}
            </Button>
            <UncontrolledTooltip
                placement="left"
                target="save-btn"
                style={{
                    maxWidth: '15rem'
                }}
                className='custom-map-tooltip'
            >
                Save current view
            </UncontrolledTooltip>

            <EyeShow
                icon={icon}
                vehicle={vehicle}
                areaUuid={areaUuid}
                setIcon={setIcon}
                hidePaths={hidePaths}
                setHidePaths={setHidePaths}
                buttonState={buttonState}
                toggle={dropdownToggle}
                dropdownOpen={dropdownOpen}
                handleButtonEvent={handleButtonEvent}
                place="minimap"
                showTagZonesNames={showTagZonesNames}
                setShowTagZonesNames={setShowTagZonesNames}
                showVehiclesNames={showVehiclesNames}
                setShowVehiclesNames={setShowVehiclesNames}
            />

            <Button
                size="sm"
                id="estpose"
                className={`${buttonClass} mt-2`}
                onClick={() => setEstimatedPose(true)}
                onMouseDown={handleButtonEvent({ button: 'estPoseIsClicked', value: true })}
                onMouseUp={handleButtonEvent({ button: 'estPoseIsClicked', value: false })}
                onMouseEnter={handleButtonEvent({ button: 'estPoseIsHover', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'estPoseIsHover', value: false })}
            >
                {renderDifferentActions(
                    buttonState.estPoseIsHover,
                    '/svgs/map-editor/est-pose-hover.svg',
                    buttonState.estPoseIsClicked,
                    '/svgs/map-editor/est-pose-selected.svg',
                    '/svgs/map-editor/est-pose.svg',
                    'zoom-in'
                )}
            </Button>
            <UncontrolledTooltip
                placement="left"
                target="estpose"
                style={{
                    maxWidth: '15rem'
                }}
                className='custom-map-tooltip'
            >
                Estimated pose for the robot
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="zoomin"
                className={`${buttonClass} my-2`}
                onClick={() => handleZoomBtns(stageRef, true, sizes, setSize)}
                onMouseDown={handleButtonEvent({ button: 'zoomIsClicked', value: true })}
                onMouseUp={handleButtonEvent({ button: 'zoomIsClicked', value: false })}
                onMouseEnter={handleButtonEvent({ button: 'zoomIsHover', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'zoomIsHover', value: false })}
            >
                {renderDifferentActions(
                    buttonState.zoomIsHover,
                    '/svgs/map-editor/zoom-in-hover.svg',
                    buttonState.zoomIsClicked,
                    '/svgs/map-editor/zoom-in-clicked.svg',
                    '/svgs/map-editor/zoom-in.svg',
                    'zoom-in'
                )}
            </Button>
            <UncontrolledTooltip
                placement="left"
                target="zoomin"
                style={{
                    maxWidth: '15rem',
                    textAlign:'center'
                }}
                className='custom-map-tooltip'
            >
                Zoom in
                <br />
                (CMD/Ctrl + scroll up)
            </UncontrolledTooltip>
            <Button
                size="sm"
                id="zoomout"
                className={buttonClass}
                onClick={() => handleZoomBtns(stageRef, false, sizes, setSize)}
                onMouseDown={handleButtonEvent({ button: 'zoomOutIsClicked', value: true })}
                onMouseUp={handleButtonEvent({ button: 'zoomOutIsClicked', value: false })}
                onMouseEnter={handleButtonEvent({ button: 'zoomOutIsHover', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'zoomOutIsHover', value: false })}
            >
                {renderDifferentActions(
                    buttonState.zoomOutIsHover,
                    '/svgs/map-editor/zoom-out-hover.svg',
                    buttonState.zoomOutIsClicked,
                    '/svgs/map-editor/zoom-out-clicked.svg',
                    '/svgs/map-editor/zoom-out.svg',
                    'zoom-out'
                )}
            </Button>
            <UncontrolledTooltip
                placement="left"
                target="zoomout"
                style={{
                    maxWidth: '15rem',
                    textAlign:'center'
                }}
                className='custom-map-tooltip'
            >
                Zoom out
                <br />
                (CMD/Ctrl + scroll down)
            </UncontrolledTooltip>

            {/* <UncontrolledTooltip placement="left" target="hide_paths" className="tooltip-style">
                Hide paths
            </UncontrolledTooltip> */}
        </div>
    )
}
