import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Row, Form, Label, Spinner } from 'reactstrap'
import { Formik } from 'formik'
import { selectors } from 'features/dashboard'
import CustomCheckbox from './CustomCheckbox'
import { getNotifications, updateNotifications } from 'features/dashboard/Notification.actionTypes'

const notificationNone = {
    task_aborted_none: false,
    task_stuck_none: false,
    task_rerun_none: false,
    task_failed_none: false,
    task_timeout_none: false,
    map_updated_none_team: false,
    map_updated_none_org: false,
    new_user_none_team: false,
    new_user_none_org: false,
    none_stat_email_team: false,
    none_stat_email_org: false,
    vehicle_notification_none: false,
    team_status_none: false,
    task_terminated_none: false,
    task_completed_none: false,
}

const Notifications = () => {
    const dispatch = useDispatch()
    const notification = useSelector(selectors.getNotification)

    const handleSubmit = (outputs, action) => {
        const {
            task_aborted_none,
            task_stuck_none,
            task_rerun_none,
            task_failed_none,
            task_timeout_none,
            task_terminated_none,
            map_updated_none_team,
            map_updated_none_org,
            new_user_none_team,
            new_user_none_org,
            none_stat_email_team,
            none_stat_email_org,
            team_status_none,
            task_completed_none,
            ...data
        } = outputs

        action.setStatus('saving')
        dispatch(updateNotifications(data)).then(({ error }) => {
            action.setSubmitting(false)
            if (error) {
                action.setErrors(error.response.data)
            } else {
                dispatch(getNotifications())
                action.setStatus('saved')
            }
        })
    }

    return (
        <div style={{ paddingRight: '32px' }}>
            <div className="mt-5">
                <h4>Notifications</h4>
                <span>Select when and how you’ll be notified.</span>
            </div>
            <hr />
            <Formik
                initialValues={{
                    ...notification.settings,
                    ...notificationNone,
                }}
                enableReinitialize={true}
                onSubmit={(data, action) => handleSubmit(data, action)}
            >
                {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <Row
                            className="d-flex justify-content-between align-items-start"
                            style={{ paddingRight: '16px' }}
                        >
                            <Col md={6} className="d-flex flex-column w-50">
                                <Label for="task-updates" style={{ fontWeight: 'bold' }}>
                                    Vehicle updates
                                </Label>
                                <span>
                                    Select if you’d like to be notified with robot-generated
                                    notifications.
                                </span>
                            </Col>
                            <Col
                                md={6}
                                className="my-2 d-flex align-items-center justify-content-between"
                            >
                                <span>Vehicle notification</span>
                                <CustomCheckbox
                                    setFieldValue={setFieldValue}
                                    values={[
                                        {
                                            label: 'None',
                                            value: !values.vehicle_notification,
                                            name: 'vehicle_notification_none',
                                        },
                                        {
                                            label: 'All',
                                            value: values.vehicle_notification,
                                            name: 'vehicle_notification',
                                            none: 'vehicle_notification_none',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row
                            className="d-flex justify-content-between align-items-start"
                            style={{ paddingRight: '16px' }}
                        >
                            <Col md={6} className="d-flex flex-column w-50">
                                <Label for="task-updates" style={{ fontWeight: 'bold' }}>
                                    Mission updates
                                </Label>
                                <span>
                                    Select when you’ll be notified when the following events occur.
                                </span>
                            </Col>

                            <Col md={6}>
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A mission is aborted</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.task_aborted_none,
                                                name: 'task_aborted_none',
                                                web: 'task_aborted_notification',
                                                email: 'task_aborted_email',
                                            },
                                            {
                                                value: values.task_aborted_notification,
                                                name: 'task_aborted_notification',
                                                label: 'Web',
                                                none: 'task_aborted_none',
                                            },
                                            {
                                                value: values.task_aborted_email,
                                                name: 'task_aborted_email',
                                                label: 'Email',
                                                none: 'task_aborted_none',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A mission is in rerun</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.task_rerun_none,
                                                name: 'task_rerun_none',
                                                web: 'task_rerun_notification',
                                                email: 'task_rerun_email',
                                            },
                                            {
                                                value: values.task_rerun_notification,
                                                name: 'task_rerun_notification',
                                                label: 'Web',
                                                none: 'task_rerun_none',
                                            },
                                            {
                                                value: values.task_rerun_email,
                                                name: 'task_rerun_email',
                                                label: 'Email',
                                                none: 'task_rerun_none',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A mission is completed</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.task_completed_none,
                                                name: 'task_completed_none',
                                                web: 'task_completed_notification',
                                                email: 'task_completed_email',
                                            },
                                            {
                                                value: values.task_completed_notification,
                                                name: 'task_completed_notification',
                                                label: 'Web',
                                                none: 'task_completed_none',
                                            },
                                            {
                                                value: values.task_completed_email,
                                                name: 'task_completed_email',
                                                label: 'Email',
                                                none: 'task_completed_none',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A mission is stuck</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.task_stuck_none,
                                                name: 'task_stuck_none',
                                                web: 'task_stuck_notification',
                                                email: 'task_stuck_email',
                                            },
                                            {
                                                value: values.task_stuck_notification,
                                                name: 'task_stuck_notification',
                                                label: 'Web',
                                                none: 'task_stuck_none',
                                            },
                                            {
                                                value: values.task_stuck_email,
                                                name: 'task_stuck_email',
                                                label: 'Email',
                                                none: 'task_stuck_none',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A mission has failed</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.task_failed_none,
                                                name: 'task_failed_none',
                                                web: 'task_failed_notification',
                                                email: 'task_failed_email',
                                            },
                                            {
                                                value: values.task_failed_notification,
                                                name: 'task_failed_notification',
                                                label: 'Web',
                                                none: 'task_failed_none',
                                            },
                                            {
                                                value: values.task_failed_email,
                                                name: 'task_failed_email',
                                                label: 'Email',
                                                none: 'task_failed_none',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A mission has been terminated</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.task_terminated_none,
                                                name: 'task_terminated_none',
                                                web: 'task_terminated_notification',
                                                email: 'task_terminated_email',
                                            },
                                            {
                                                value: values.task_terminated_notification,
                                                name: 'task_terminated_notification',
                                                label: 'Web',
                                                none: 'task_terminated_none',
                                            },
                                            {
                                                value: values.task_terminated_email,
                                                name: 'task_terminated_email',
                                                label: 'Email',
                                                none: 'task_terminated_none',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>There has been a timeout</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.task_timeout_none,
                                                name: 'task_timeout_none',
                                                web: 'task_timeout_notification',
                                                email: 'task_timeout_email',
                                            },
                                            {
                                                value: values.task_timeout_notification,
                                                name: 'task_timeout_notification',
                                                label: 'Web',
                                                none: 'task_timeout_none',
                                            },
                                            {
                                                value: values.task_timeout_email,
                                                name: 'task_timeout_email',
                                                label: 'Email',
                                                none: 'task_timeout_none',
                                            },
                                        ]}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row
                            className="d-flex justify-content-between align-items-start"
                            style={{ paddingRight: '16px' }}
                        >
                            <Col md={6} className="d-flex flex-column w-50">
                                <Label for="task-updates" style={{ fontWeight: 'bold' }}>
                                    Map updates
                                </Label>
                                <span>
                                    Select when you’ll be notified when the following events occur.
                                </span>
                            </Col>

                            <Col md={6}>
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A team map has been changed</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.map_updated_none_team,
                                                name: 'map_updated_none_team',
                                                web: 'map_updated_notification_team',
                                                email: 'map_updated_email_team',
                                            },
                                            {
                                                value: values.map_updated_notification_team,
                                                name: 'map_updated_notification_team',
                                                label: 'Web',
                                                none: 'map_updated_none_team',
                                            },
                                            {
                                                value: values.map_updated_email_team,
                                                name: 'map_updated_email_team',
                                                label: 'Email',
                                                none: 'map_updated_none_team',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>An organisation map has been changed</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.map_updated_none_org,
                                                name: 'map_updated_none_org',
                                                web: 'map_updated_notification_org',
                                                email: 'map_updated_email_org',
                                            },
                                            {
                                                value: values.map_updated_notification_org,
                                                name: 'map_updated_notification_org',
                                                label: 'Web',
                                                none: 'map_updated_none_org',
                                            },
                                            {
                                                value: values.map_updated_email_org,
                                                name: 'map_updated_email_org',
                                                label: 'Email',
                                                none: 'map_updated_none_org',
                                            },
                                        ]}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row
                            className="d-flex justify-content-between align-items-start"
                            style={{ paddingRight: '16px' }}
                        >
                            <Col md={6} className="d-flex flex-column w-50">
                                <Label for="task-updates" style={{ fontWeight: 'bold' }}>
                                    User updates
                                </Label>
                                <span>
                                    Select when you’ll be notified when the following events occur.
                                </span>
                            </Col>
                            <Col md={6}>
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A new team member has been added</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.new_user_none_team,
                                                name: 'new_user_none_team',
                                                web: 'new_user_notification_team',
                                                email: 'new_user_email_team',
                                            },
                                            {
                                                value: values.new_user_notification_team,
                                                name: 'new_user_notification_team',
                                                label: 'Web',
                                                none: 'new_user_none_team',
                                            },
                                            {
                                                value: values.new_user_email_team,
                                                name: 'new_user_email_team',
                                                label: 'Email',
                                                none: 'new_user_none_team',
                                            },
                                        ]}
                                    />
                                </div>

                                <hr />

                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>A new organisation member has been added</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.new_user_none_org,
                                                name: 'new_user_none_org',
                                                web: 'new_user_notification_org',
                                                email: 'new_user_email_org',
                                            },
                                            {
                                                value: values.new_user_notification_org,
                                                name: 'new_user_notification_org',
                                                label: 'Web',
                                                none: 'new_user_none_org',
                                            },
                                            {
                                                label: 'Email',
                                                name: 'new_user_email_org',
                                                value: values.new_user_email_org,
                                                none: 'new_user_none_org',
                                            },
                                        ]}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row
                            className="d-flex justify-content-between align-items-start"
                            style={{ paddingRight: '16px' }}
                        >
                            <Col md={6} className="d-flex flex-column w-50">
                                <Label for="task-updates" style={{ fontWeight: 'bold' }}>
                                    Team updates
                                </Label>
                                <span>
                                    Select when you’ll be notified when the following events occur.
                                </span>
                                <span>
                                    E.g. Emergency stop has been triggered or released.
                                </span>
                            </Col>
                            <Col md={6}>
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>Team status updates</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.team_status_none,
                                                name: 'team_status_none',
                                                web: 'team_status_notification',
                                                email: 'team_status_email',
                                            },
                                            {
                                                value: values.team_status_notification,
                                                name: 'team_status_notification',
                                                label: 'Web',
                                                none: 'team_status_none',
                                            },
                                            {
                                                value: values.team_status_email,
                                                name: 'team_status_email',
                                                label: 'Email',
                                                none: 'team_status_none',
                                            },
                                        ]}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row
                            className="d-flex justify-content-between align-items-start"
                            style={{ paddingRight: '16px' }}
                        >
                            <Col md={6} className="d-flex flex-column w-50">
                                <Label for="task-updates" style={{ fontWeight: 'bold' }}>
                                    Analytics updates
                                </Label>
                                <span>
                                    Select when you’d like to receive new analytics reports (email
                                    only).
                                </span>
                            </Col>

                            <Col md={6}>
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                    <span>Team analytics</span>
                                    <CustomCheckbox
                                        setFieldValue={setFieldValue}
                                        values={[
                                            {
                                                label: 'None',
                                                value: values.none_stat_email_team,
                                                name: 'none_stat_email_team',
                                                web: 'daily_stat_email_team',
                                                email: 'monthly_stat_email_team',
                                            },
                                            {
                                                value: values.daily_stat_email_team,
                                                name: 'daily_stat_email_team',
                                                label: 'Daily',
                                                none: 'none_stat_email_team',
                                            },
                                            {
                                                label: 'Monthly',
                                                name: 'monthly_stat_email_team',
                                                value: values.monthly_stat_email_team,
                                                none: 'none_stat_email_team',
                                            },
                                        ]}
                                    />
                                </div>
                                <hr />
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                <span>Organisation analytics</span>
                                <CustomCheckbox
                                    setFieldValue={setFieldValue}
                                    values={[
                                        {
                                            label: 'None',
                                            value: values.none_stat_email_org,
                                            name: 'none_stat_email_org',
                                            web: 'daily_stat_email_org',
                                            email: 'monthly_stat_email_org',
                                        },
                                        {
                                            value: values.daily_stat_email_org,
                                            name: 'daily_stat_email_org',
                                            label: 'Daily',
                                            none: 'none_stat_email_org',
                                        },
                                        {
                                            label: 'Monthly',
                                            name: 'monthly_stat_email_org',
                                            value: values.monthly_stat_email_org,
                                            none: 'none_stat_email_org',
                                        },
                                    ]}
                                />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <div className="d-flex justify-content-end mr-3 my-4">
                            <Button
                                type="submit"
                                className="save-btn-modal modals-new-btns"
                                style={{ height: '2.5rem', minWidth: '6rem' }}
                                disabled={
                                    JSON.stringify({
                                        ...notification.settings,
                                        ...notificationNone,
                                    }) === JSON.stringify(values)
                                }
                            >
                                Save
                                {isSubmitting && (
                                    <Spinner
                                        size="sm"
                                        color="light"
                                        className="ml-2"
                                    />
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Notifications
