import React, { Component } from 'react'
import { Card, Row, Col, CardBody, UncontrolledTooltip } from 'reactstrap'
import { produce } from 'immer'
import { Stage, Layer, Line, Image, Text } from 'react-konva'
import { connect } from 'react-redux'
import { fileClient } from 'middleware/api'
import {
    getMapAreaOfInterest,
    updateImage,
    resetImage,
    getMapImageDetails,
    createRestrictedZones,
    getAnalysisStatus,
    updateOriginPoint,
    createAreaZones,
    getAreaZones,
    updateAreaZone,
    getMapStations,
    getZonesPaths,
    updateMapToRobot,
    resetZones,
    getAreaZonesAll,
    getMapAreaDetails,
    canUndo,
    canRedo,
    doUndoRedo,
    getZoneDimensions,
} from 'features/dashboard/Map.actionTypes'
import { getVehicleCategories } from 'features/dashboard/Vehicle.actionTypes'
import { selectors } from 'features/dashboard'
import MapObstacle from './MapObstacle'
import { ZONES_STYLES } from 'export'
import { TITLE } from 'config'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import Annotation from './Annotation'
import {
    BiCurrentLocation,
    // BiRotateLeft,
    // BiRotateRight,
} from 'react-icons/bi'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import MiniNavbar from './MapEditorNavbar'
import LeavingPrompt from './LeavingPrompt'
import OriginPoint from './OriginPoint'
import MapEditorModal from './MapEditorModal'
import MapEditorStations from './MapEditorStations'
import MapEditorPaths from './MapEditorPaths'
import { RenderRightIcons, RenderTopIcons } from './MapEditorHelpers'
import { handleWheel, handleZoomBtns } from './helpers'
import MapEditorDevices from './MapEditorDevices'
import { getPresetTasksV2, getZonePresets } from 'features/dashboard/Task.actionTypes'
import MapEditorEraser from './MapEditorEraser'
import { getDevices } from 'features/dashboard/RosSetup.actionTypes'
import { getMapSource, saveMapSource } from 'db'
import MapPreZone from './MapPreZone'

class MapCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editPoint: false,
            modal: false,
            image: null,
            originalImage: null,
            status: 'loading',
            stageRef: null,
            sizes: {
                ratio: 1,
                width: 1,
                height: 600,
                scale: 1,
            },
            newZone: {},
            newVersionZone: {},
            windows: {
                area: true,
                obstacles: true,
                safe_zones: true,
                hide_all: false,
            },
            grid: false,
            hoveredZone: {},
            token: window.localStorage.getItem('token'),
            annotations: [],
            newDrawable: [],
            erase: false,
            eraserSize: 20,
            lines: [],
            lastLine: {},
            figPoints: [],
            setLine: false,
            setSquare: false,
            setZone: false,
            selectedId: null,
            lineId: null,
            delId: null,
            obstacleId: null,
            safeDelId: null,
            forbiddenId: null,
            actionId: null,
            chargingId: null,
            restingId: null,
            storkeColor: 'black',
            hoverover: false,
            pen: false,
            setStatus: '',
            openModal: '',
            hover: '',
            cursor: {
                x: null,
                y: null,
            },
            setOriginalImage: JSON.parse(localStorage.getItem('original-image')),
            smallerMapSize: 10000,
            storkwidth: 5,
            biggerMapSize: 1000000,
            resetting: false,
            zones: this.props.zones,
            checkForChanges: false,
            activeTab: 'Map Editor',
            unsavedChangesArray: [],
            redoArray: [],
            allChanges: [],
            newlines: false,
            notification: false,
            isSelected: false,
            prompt: false,
            triggerExit: { onOk: false, path: '' },
            stageRotation: 0,
            imageX: 0,
            imageY: 0,
            clickedZone: {},
            isClicked: false,
            hidePaths: false,
            sizeText: 0,
            sizeOrigin: 0,
            showTagZonesNames: false,
            currentPage: 1,
            isChanged: false,
            areas: this.props.areas,
            areaDetails: this.props.areaDetails,
            zonesPaths: this.props.zonesPaths,
            stations: this.props.stations,
            devices: this.props.devices,
            tasks: this.props.tasks,
            canUndo: this.props.canUndo,
            canRedo: this.props.canRedo,
            restrictedZones: this.props.restrictedZones,
        }

        this.newZoneRef = React.createRef()
        this.isDrawing = React.createRef()
        this.mapRef = React.createRef()
        this.imageRef = React.createRef()

        this.toggle = this.toggle.bind(this)
        this.handleStageClick = this.handleStageClick.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.NewZoneLayer = this.NewZoneLayer.bind(this)
        this.handleNewZone = this.handleNewZone.bind(this)
        this.handleCreateZoneOnClick = this.handleCreateZoneOnClick.bind(this)
        this.cancelNewZone = this.cancelNewZone.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.getCurrentMousePointer = this.getCurrentMousePointer.bind(this)
        this.loadData = this.loadData.bind(this)
        this.switchMap = this.switchMap.bind(this)
        this.onStageRefUpdate = this.onStageRefUpdate.bind(this)
        this.handlePointer = this.handlePointer.bind(this)
        this.handleExport = this.handleExport.bind(this)
        this.handleMouseDown = this.handleMouseDown.bind(this)
        this.handleMouseUp = this.handleMouseUp.bind(this)
        this.handleErase = this.handleErase.bind(this)
        this.handleSquare = this.handleSquare.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.squareDown = this.squareDown.bind(this)
        this.resetMap = this.resetMap.bind(this)
        this.handleDrawing = this.handleDrawing.bind(this)
        this.setHover = this.setHover.bind(this)
        this.revertMap = this.revertMap.bind(this)
        this.togglePrompt = this.togglePrompt.bind(this)
        this.setCheckForChanges = this.setCheckForChanges.bind(this)
        this.handleUpdateOrigin = this.handleUpdateOrigin.bind(this)
        this.handleNewVersionZoneClick = this.handleNewVersionZoneClick.bind(this)
        this.handleActionZoneClick = this.handleActionZoneClick.bind(this)
        this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this)
        this.handleEditZone = this.handleEditZone.bind(this)
        this.hanldeUpdateMapToRobot = this.hanldeUpdateMapToRobot.bind(this)
        this.handleHidePaths = this.handleHidePaths.bind(this)
        this.hasOwn = this.hasOwn.bind(this)
        this.handleShowTagZonesNames = this.handleShowTagZonesNames.bind(this)
        this.handleUndoNew = this.handleUndoNew.bind(this)
        this.handleRedoNew = this.handleRedoNew.bind(this)
        this.updateMapSize = this.updateMapSize.bind(this)
        this.getPreZoneDimensions = this.getPreZoneDimensions.bind(this)
        this.unblock = null
    }

    //use this code in the future, for discarding all current changes
    // discardChanges() {
    //     this.setState({
    //         checkForChanges: false,
    //         unsavedChangesArray: [],
    //         redoArray: [],
    //         allChanges: [],
    //         newlines: false,
    //         annotations: [],
    //         lines: [],
    //         figPoints: [],
    //     })
    // }

    getPreZoneDimensions(data) {
        const uuid = this.props.areas.uuid
        this.props.dispatch(getZoneDimensions({ data, uuid })).then((res) => {
            if (!res.error) {
                this.setState({
                    newVersionZone: {
                        ...this.state.newVersionZone,
                        ...res.payload.data,
                    },
                })
            }
        })
    }

    setCheckForChanges() {
        this.setState((prevState) => ({
            checkForChanges: !this.state.checkForChanges,
            triggerExit: { ...prevState.triggerExit, onOk: true },
        }))
    }

    togglePrompt() {
        this.setState({
            prompt: !this.state.prompt,
        })
    }

    setHover() {
        return this.state.hover
    }

    squareDown() {
        this.setState(
            produce((draft) => {
                draft.selectedId = null
                draft.lineId = null
                draft.hover = ''
            })
        )
    }

    handleShowTagZonesNames() {
        this.setState(
            produce((draft) => {
                draft.showTagZonesNames = !draft.showTagZonesNames
            })
        )
    }

    handleMouseDown(e) {
        const { setSquare, setZone, newVersionZone, newZone, stageRef, newDrawable, erase } =
            this.state
        const stage = stageRef
        const { x, y } = this.getCurrentMousePointer(stage)

        if (erase) {
            this.handleDrawing()
        }

        if (newDrawable.length === 0) {
            if (setSquare === true) {
                this.setState(
                    produce((draft) => {
                        draft.newDrawable = [
                            {
                                x,
                                y,
                                width: 0,
                                height: 0,
                                key: '0',
                                newZone: newZone.type,
                            },
                        ]
                    })
                )
            }
            if (
                setZone &&
                (newVersionZone.zone_type === 'forbidden' ||
                    newVersionZone.zone_type === 'action' ||
                    newVersionZone.zone_type === 'tag' ||
                    newVersionZone.zone_type === 'obstacle')
            ) {
                this.setState(
                    produce((draft) => {
                        draft.newDrawable = [
                            {
                                x,
                                y,
                                width: 0,
                                height: 0,
                                key: '0',
                                ...newVersionZone,
                            },
                        ]
                    })
                )
            }
        }
    }

    handleMouseMove() {
        const stage = this.state.stageRef
        const newZoneLayer = this.newZoneRef.current
        const { newZone, newVersionZone } = this.state
        stage.container().style.cursor = 'pointer'
        if (newZone.points?.length === 0 || newVersionZone.points?.length === 0) return
        const { x, y } = this.getCurrentMousePointer(stage)
        newZoneLayer.setPoints([...newVersionZone.points?.flat(), x, y])
        newZoneLayer.draw()
        newZoneLayer.parent.draw()
    }

    handleSquare() {
        if (this.state.selectedId === null && this.state.newDrawable.length === 1) {
            const sx = Math.floor(this.state.newDrawable[0].x)
            const sy = Math.floor(this.state.newDrawable[0].y)
            const stage = this.state.stageRef
            const { x, y } = this.getCurrentMousePointer(stage)

            this.setState(
                produce((draft) => {
                    draft.newDrawable = [
                        this.state.setSquare
                            ? {
                                  x: sx,
                                  y: sy,
                                  width: Math.floor(x - sx),
                                  height: Math.floor(y - sy),
                                  key: '0',
                                  newZone: this.state.newZone.type,
                                  points: [
                                      ...this.state.newZone.points.flat(),
                                      [sx, sy],
                                      [Math.floor(sx + (x - sx)), sy],
                                      [Math.floor(sx + (x - sx)), Math.floor(y - sy + sy)],
                                      [sx, Math.floor(y - sy + sy)],
                                  ],
                              }
                            : {
                                  x: sx,
                                  y: sy,
                                  width: Math.floor(x - sx),
                                  height: Math.floor(y - sy),
                                  key: '0',
                                  newZone: this.state.newVersionZone.zone_type,
                                  points: [
                                      [sx, sy],
                                      [Math.floor(sx + (x - sx)), sy],
                                      [Math.floor(sx + (x - sx)), Math.floor(y - sy + sy)],
                                      [sx, Math.floor(y - sy + sy)],
                                  ],
                              },
                    ]
                })
            )
        }
    }

    handleMouseUp() {
        const { newDrawable, stageRef, annotations, newZone, setSquare, newVersionZone } =
            this.state
        const { uuid } = this.props.areas
        if (newDrawable.length === 1) {
            const sx = Math.round(newDrawable[0].x)
            const sy = Math.round(newDrawable[0].y)
            const stage = stageRef

            const { x, y } = this.getCurrentMousePointer(stage)
            this.setState(
                produce((draft) => {
                    draft.newDrawable = []
                })
            )

            if (
                newVersionZone.zone_type === 'forbidden' ||
                newVersionZone.zone_type === 'action' ||
                newVersionZone.zone_type === 'tag'
            ) {
                const zoneToAdd = {
                    ...newVersionZone,
                    x: sx,
                    y: sy,
                    width: Math.floor(x - sx),
                    height: Math.floor(y - sy),
                    rotation: 0,
                    // points: [
                    //     [sx, sy],
                    //     [Math.floor(sx + (x - sx)), sy],
                    //     [Math.floor(sx + (x - sx)), Math.floor(y - sy + sy)],
                    //     [sx, Math.floor(y - sy + sy)],
                    // ],
                }
                this.props.dispatch(createAreaZones({ uuid, data: zoneToAdd })).then((res) => {
                    this.setState(
                        produce((draft) => {
                            draft.editPoint = false
                            draft.isSelected = false
                            draft.setZone = false
                            draft.newVersionZone = {}
                        })
                    )
                    this.props.dispatch(getAreaZones({ uuid, type: 'forbidden' }))
                    this.props.dispatch(getAreaZones({ uuid, type: 'action' }))
                    this.props.dispatch(getAreaZones({ uuid, type: 'tag' }))
                    this.props.dispatch(getZonesPaths(uuid))
                    this.props.dispatch(getMapStations({ uuid }))
                    this.props.dispatch(canRedo(uuid))
                    this.props.dispatch(canUndo(uuid))
                })
            } else {
                const annotationToAdd = {
                    x: sx,
                    y: sy,
                    width: Math.floor(x - sx),
                    height: Math.floor(y - sy),
                    key: annotations.length + 1,
                    newZone: newZone.type,
                    points: [
                        ...newZone.points.flat(),
                        [sx, sy],
                        [Math.floor(sx + (x - sx)), sy],
                        [Math.floor(sx + (x - sx)), Math.floor(y - sy + sy)],
                        [sx, Math.floor(y - sy + sy)],
                    ],
                }

                this.setState(
                    produce((draft) => {
                        draft.annotations.push(annotationToAdd)
                        draft.allChanges.push(annotationToAdd)
                        draft.checkForChanges = true
                        if (draft.redoArray.length > 0) draft.redoArray = []
                    })
                )
            }
        }
        this.isDrawing.current = false
        if (setSquare === true) {
            this.setState(
                produce((draft) => {
                    draft.selectedId = annotations.length
                })
            )
        }
        this.setState(
            produce((draft) => {
                draft.setSquare = false
                draft.setZone = false
                draft.hover = ''
                if (draft.newlines === true) {
                    draft.allChanges.push(
                        draft.lines[draft.lines.length > 0 ? draft.lines.length - 1 : 0]
                    )
                    draft.newlines = false
                    if (draft.redoArray.length > 0) draft.redoArray = []
                }
            })
        )
    }

    handleKeyDown() {
        switch (true) {
            case this.state.selectedId !== null: {
                const newDrawables = this.state.annotations.filter(
                    (annotation) => annotation.key !== this.state.selectedId
                )
                this.setState(
                    produce((draft) => {
                        draft.annotations = newDrawables
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.lineId !== null: {
                const newFig = this.state.figPoints.filter((fig) => fig.id !== this.state.lineId)

                this.setState(
                    produce((draft) => {
                        draft.figPoints = newFig
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.safeDelId !== null: {
                const newSafeZones = this.state.MapSafeZones.filter(
                    (zone) => zone.uuid !== this.state.safeDelId
                )

                this.setState(
                    produce((draft) => {
                        draft.MapSafeZones = newSafeZones
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.obstacleId !== null: {
                const newObstacles = this.state.restrictedZones.filter(
                    (zone) => zone.uuid !== this.state.obstacleId
                )

                this.setState(
                    produce((draft) => {
                        draft.restrictedZones = newObstacles
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.chargingId !== null: {
                const newChargingZones = this.state.zones.charging.filter(
                    (zone) => zone.uuid !== this.state.chargingId
                )

                this.setState(
                    produce((draft) => {
                        draft.zones.charging = newChargingZones
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.forbiddenId !== null: {
                const newForbiddenZones = this.state.zones.forbidden.filter(
                    (zone) => zone.uuid !== this.state.forbiddenId
                )

                this.setState(
                    produce((draft) => {
                        draft.zones.forbidden = newForbiddenZones
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.tagId !== null: {
                const newTagZones = this.state.zones.tag.filter(
                    (zone) => zone.uuid !== this.state.tagId
                )

                this.setState(
                    produce((draft) => {
                        draft.zones.tag = newTagZones
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.actionId !== null: {
                const newActionZones = this.state.zones.action.filter(
                    (zone) => zone.uuid !== this.state.actionId
                )

                this.setState(
                    produce((draft) => {
                        draft.zones.action = newActionZones
                        draft.checkForChanges = true
                    })
                )
                break
            }
            case this.state.restingId !== null: {
                const newRestingZones = this.state.zones.resting.filter(
                    (zone) => zone.uuid !== this.state.restingId
                )

                this.setState(
                    produce((draft) => {
                        draft.zones.resting = newRestingZones
                        draft.checkForChanges = true
                    })
                )
                break
            }
            default:
                return
        }
    }

    handleErase() {
        if (!this.isDrawing.current) {
            return
        }

        let { lastLine, lines, eraserSize } = this.state
        const stage = this.state.stageRef
        stage.container().style.cursor = 'pointer'

        const { x, y } = this.getCurrentMousePointer(stage)

        lastLine = Object.assign({}, lines[lines.length - 1])
        lastLine.points = lastLine.points?.concat([x, y])

        // Save the eraser size for the current line
        lastLine.eraserSize = eraserSize

        lines = [...lines]
        lines.splice(lines.length - 1, 1, lastLine)

        this.setState(
            produce((draft) => {
                draft.lines = lines.concat()
                draft.newlines = true
            })
        )
    }

    handleDrawing() {
        const stage = this.state.stageRef
        const { x, y } = this.getCurrentMousePointer(stage)
        const { erase, lines } = this.state
        this.isDrawing.current = true
        this.setState(
            produce((draft) => {
                draft.lines = [...lines, { erase, points: [x, y] }]
                draft.checkForChanges = true
            })
        )
    }

    handleHover() {
        const stage = this.state.stageRef
        this.setState(
            produce((draft) => {
                draft.cursor = this.getCurrentMousePointer(stage)
            })
        )
    }

    onStageRefUpdate(node) {
        if (node && !this.state.stageRef) {
            this.setState(
                produce((draft) => {
                    draft.stageRef = node
                })
            )
        }
    }

    toggle() {
        this.setState(
            produce((draft) => {
                draft.modal = !draft.modal
            })
        )
    }

    async switchMap() {
        const { uuid } = this.props.areas
        const { image } = this.props.areaDetails
        const extracted = image?.match(/\/areas\/(\d+)-/)[1]
        const localStorageKey = `gen-svg-${extracted}-${uuid}`

        // Helper function to update state with the image
        const setImageAndState = (imageSrc) => {
            const image = new window.Image()
            image.crossOrigin = 'Anonymous'
            image.src = this.state.setOriginalImage
                ? this.props.ImagesData?.displayable_image
                : imageSrc

            this.setState(
                produce((draft) => {
                    draft.image = image
                    draft.status = 'loaded'
                    draft.sizeText = 14 / this.state.stageRef?.scaleX()
                    draft.sizeOrigin = 14 / this.state.stageRef?.scaleX()
                })
            )
        }

        try {
            // Await cached image from IndexedDB
            const cachedImage = await getMapSource(localStorageKey)

            if (cachedImage) {
                setImageAndState(cachedImage) // Use cached image if available
            }
        } catch (error) {
            console.error('Error loading cached image:', error)
            // Optional: set error state if needed
            this.setState({ status: 'image-error' })
        }
    }

    async loadData() {
        const { uuid } = this.props.areas
        const { image } = this.props.areaDetails
        const slug = this.props.match.params.slug
        const currentPage = this.state.currentPage
        const extracted = image?.match(/\/areas\/(\d+)-/)[1]
        const localStorageKey = `gen-svg-${extracted}-${uuid}`
        this.setState({ status: 'loading' })

        // Helper function to update state with the image
        const setImageAndState = (imageSrc) => {
            const image = new window.Image()
            image.crossOrigin = 'Anonymous'
            image.src = this.state.setOriginalImage
                ? this.props.ImagesData?.displayable_image
                : imageSrc

            this.setState(
                produce((draft) => {
                    draft.image = image
                    draft.status = 'loaded'
                    draft.sizeText = 14 / this.state.stageRef?.scaleX()
                    draft.sizeOrigin = 14 / this.state.stageRef?.scaleX()
                })
            )
        }

        try {
            // Try to get cached image from IndexedDB
            const cachedImage = await getMapSource(localStorageKey)

            if (cachedImage) {
                Promise.all([
                    this.props.dispatch(getZonesPaths(uuid)),
                    this.props.dispatch(getMapStations({ uuid })),
                    this.props.dispatch(getDevices({ slug })),
                    this.props.dispatch(getAreaZonesAll(uuid)),
                    this.props.dispatch(getMapAreaDetails(uuid)),
                    this.props.dispatch(getMapImageDetails(uuid)),
                    this.props.dispatch(getMapAreaOfInterest(uuid)),
                    this.props.dispatch(getVehicleCategories({ slug })),
                    this.props.dispatch(getPresetTasksV2({ slug, page: currentPage })),
                    this.props.dispatch(canUndo(uuid)),
                    this.props.dispatch(canRedo(uuid)),
                ]).then(() => {
                    setImageAndState(cachedImage)
                })
                return // Exit early
            }

            // If no cached image, fetch from server
            const [imageRes] = await Promise.all([
                fileClient.get(`indoors/area/${uuid}/gen.svg`),
                this.props.dispatch(getZonesPaths(uuid)),
                this.props.dispatch(getMapStations({ uuid })),
                this.props.dispatch(getDevices({ slug })),
                this.props.dispatch(getAreaZonesAll(uuid)),
                this.props.dispatch(getMapAreaDetails(uuid)),
                this.props.dispatch(getMapImageDetails(uuid)),
                this.props.dispatch(getMapAreaOfInterest(uuid)),
                this.props.dispatch(getVehicleCategories({ slug })),
                this.props.dispatch(getPresetTasksV2({ slug, page: currentPage })),
                this.props.dispatch(canUndo(uuid)),
                this.props.dispatch(canRedo(uuid)),
            ])

            // Read the image from response and update state
            const reader = new window.FileReader()
            reader.readAsDataURL(imageRes.data)
            reader.onload = async () => {
                const imageData = reader.result

                // Save the image to IndexedDB and update state
                try {
                    await saveMapSource(localStorageKey, imageData)
                    setImageAndState(imageData)
                } catch {
                    this.setState({ status: 'image-error' })
                }
            }
        } catch (error) {
            console.error('Error loading data:', error)
            this.setState({ status: 'image-error' })
        }
    }

    componentDidMount() {
        document.title = `${TITLE} - Manage Map`
        if (this.props.team?.details?.operation_type !== 'inside') {
            window.location = '../outside-modify/'
            return
        }

        this.loadData()
        window.addEventListener('beforeunload', this.onUnload)

        const { history } = this.props
        this.unblock = history.block((location) => {
            const { slug } = this.props.match.params
            const { checkForChanges } = this.state
            //removes the selected items so it doesnt get saved to the map
            this.setState({
                selectedId: null,
                lineId: null,
                clickedZone: {},
                newVersionZone: {},
            })
            //if location is not the same as where you are toggle the popup
            if (location.pathname !== `/dashboard/${slug}/maps/modify/`) {
                if (checkForChanges) this.togglePrompt()
            }

            this.setState((prevState) => ({
                triggerExit: { ...prevState.triggerExit, path: location.pathname },
            }))
            //if there is changes, block the navigation
            if (checkForChanges) {
                return false
            }
            return true
        })

        const { triggerExit } = this.state
        if (triggerExit.onOk) {
            this.handleGoToIntendedPage(triggerExit.path)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onUnload)
        if (this.unblock) {
            this.unblock()
        }
    }

    escFunction = (event) => {
        if (event.key === 'Escape') {
            this.cancelNewZone()
            this.setState({
                erase: false,
            })
        }
    }

    onUnload = (e) => {
        if (this.state.checkForChanges === true) {
            // the method that will be used for both add and remove event
            e.preventDefault()
            e.returnValue = ''
        }
    }

    updateMapSize = (prevProps, prevState) => {
        if (this.props.aoi !== null && this.props.aoi !== prevProps.aoi && this.mapRef.current) {
            const { width } = this.props.aoi
            // const ratio = width / height
            this.setState({
                sizes: {
                    width: this.mapRef.current?.offsetWidth,
                    height: window.innerHeight - 180,
                    scale: (this.mapRef.current?.offsetWidth - 100) / width,
                    x: this.mapRef.current.offsetWidth,
                },
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { slug } = this.props.match.params
        const { details } = this.props.team

        if (slug === details?.slug && details?.operation_type !== 'inside') {
            window.location = '../outside-modify/'
            return
        }

        // if (this.props.areaDetails.uuid !== prevProps.areaDetails.uuid) {
        //     this.loadData()
        // }

        if (prevState.modal && !this.state.modal) {
            this.state.newVersionZone.zone_type ? this.closeModal() : this.cancelNewZone()
        }

        if (!this.mapRef.current) {
            this.loadData()
            window.location.reload()
        }

        // Initial size update
        this.updateMapSize(prevProps, prevState)

        // Handle window resize events
        const handleResize = () => {
            this.updateMapSize(prevProps, prevState)
        }

        window.addEventListener('resize', handleResize)

        if (this.props.canUndo !== prevProps.canUndo) {
            this.setState({
                canUndo: this.props.canUndo,
            })
        }

        if (this.props.canRedo !== prevProps.canRedo) {
            this.setState({
                canRedo: this.props.canRedo,
            })
        }

        if (this.state.resetting !== prevState.resetting) {
            this.loadData()
            window.location.reload()
        }

        if (this.state.setOriginalImage !== prevState.setOriginalImage) {
            this.switchMap()
        }

        if (slug !== prevProps.match.params.slug) {
            this.setState(
                produce((draft) => {
                    draft.status = 'loading'
                    draft.stageRef = null
                })
            )
            this.loadData()
        }
        if (JSON.stringify(this.props.zones?.action) !== JSON.stringify(prevProps.zones?.action)) {
            this.setState(
                produce((draft) => {
                    draft.zones.action = this.props.zones.action
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        }

        if (
            JSON.stringify(this.props.zones?.forbidden) !==
            JSON.stringify(prevProps.zones?.forbidden)
        ) {
            this.setState(
                produce((draft) => {
                    draft.zones.forbidden = this.props.zones?.forbidden
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        }

        if (JSON.stringify(this.props.zones?.tag) !== JSON.stringify(prevProps.zones?.tag)) {
            this.setState(
                produce((draft) => {
                    draft.zones.tag = this.props.zones.tag
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        }
        if (
            JSON.stringify(this.props.zones?.charging) !== JSON.stringify(prevProps.zones?.charging)
        ) {
            this.setState(
                produce((draft) => {
                    draft.zones.charging = this.props.zones.charging
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        }

        if (
            JSON.stringify(this.props.zones?.resting) !== JSON.stringify(prevProps.zones?.resting)
        ) {
            this.setState(
                produce((draft) => {
                    draft.zones.resting = this.props.zones.resting
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        }
        if (JSON.stringify(this.props.safeZones) !== JSON.stringify(prevProps.safeZones))
            this.setState(
                produce((draft) => {
                    draft.MapSafeZones = this.props.safeZones
                    draft.AreaOfInterest = this.props.aoi
                })
            )

        if (
            JSON.stringify(this.props.restrictedZones) !== JSON.stringify(prevProps.restrictedZones)
        )
            this.setState(
                produce((draft) => {
                    draft.restrictedZones = this.props.restrictedZones
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        if (JSON.stringify(this.props.stations) !== JSON.stringify(prevProps.stations)) {
            this.setState(
                produce((draft) => {
                    draft.stations = this.props.stations
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        }

        if (JSON.stringify(this.props.zonesPaths) !== JSON.stringify(prevProps.zonesPaths)) {
            this.setState(
                produce((draft) => {
                    draft.zonesPaths = this.props.zonesPaths
                    draft.AreaOfInterest = this.props.aoi
                })
            )
        }

        const { triggerExit } = this.state
        if (prevState.triggerExit.onOk !== triggerExit.onOk && triggerExit.onOk) {
            this.handleGoToIntendedPage(triggerExit.path)
        }

        document.addEventListener('keydown', this.escFunction, false)

        return () => {
            document.removeEventListener('keydown', this.escFunction, false)
            window.removeEventListener('resize', handleResize)
        }
    }

    handleGoToIntendedPage(location) {
        const { history } = this.props
        history.push(location)
    }

    revertMap() {
        const { original_height, original_width } = this.props.areaDetails
        this.setState({
            sizes: {
                width: original_width,
                height: original_height,
            },
            imageX: 0,
            imageY: 0,
            erase: false,
        })
    }

    async handleExport() {
        const { uuid } = this.props.areas

        let obstaclesData = [
            ...this.state.restrictedZones,
            ...this.state.annotations.filter((ann) => ann.newZone === 'obstacle'),
            ...this.state.figPoints.filter((fig) => fig.newZone === 'obstacle'),
        ]
        if (!this.state.setOriginalImage) {
            if (
                JSON.stringify(this.props.zones.action) !== JSON.stringify(this.state.zones.action)
            ) {
                const action = {
                    zones: [...this.state.zones.action],
                    type: 'action',
                }
                this.props.dispatch(createAreaZones({ uuid, data: action })).then(() => {
                    this.props.dispatch(getMapStations({ uuid }))
                    this.props.dispatch(getZonesPaths(uuid))
                })
            }
            if (
                JSON.stringify(this.props.zones.forbidden) !==
                JSON.stringify(this.state.zones.forbidden)
            ) {
                const forbidden = {
                    zones: [...this.state.zones.forbidden],
                    type: 'forbidden',
                }
                this.props.dispatch(createAreaZones({ uuid, data: forbidden }))
            }
            if (JSON.stringify(this.props.zones.tag) !== JSON.stringify(this.state.zones.tag)) {
                const tag = {
                    zones: [...this.state.zones.tag],
                    type: 'tag',
                }
                this.props.dispatch(createAreaZones({ uuid, data: tag }))
            }

            if (
                JSON.stringify(this.props.zones.charging) !==
                JSON.stringify(this.state.zones.charging)
            ) {
                const charging = { zones: [...this.state.zones.charging], type: 'charging' }
                this.props.dispatch(createAreaZones({ uuid, data: charging })).then(() => {
                    this.props.dispatch(getMapStations({ uuid }))
                    this.props.dispatch(getZonesPaths(uuid))
                })
            }

            if (
                JSON.stringify(this.props.zones.resting) !==
                JSON.stringify(this.state.zones.resting)
            ) {
                const resting = { zones: [...this.state.zones.resting], type: 'resting' }
                this.props.dispatch(createAreaZones({ uuid, data: resting })).then(() => {
                    this.props.dispatch(getMapStations({ uuid }))
                    this.props.dispatch(getZonesPaths(uuid))
                })
            }

            if (
                JSON.stringify(this.props.restrictedZones) !==
                JSON.stringify(this.state.restrictedZones)
            ) {
                this.props.dispatch(createRestrictedZones({ uuid, data: obstaclesData }))
            }
        } else {
            this.setState(
                produce((draft) => {
                    draft.image = ''
                    draft.status = 'loading'
                    draft.erase = false
                    draft.hover = ''
                })
            )
            const axis = {
                x: null,
                y: null,
            }
            const uri = this.state.stageRef.toDataURL(axis)
            const data = { image: uri }
            this.props.dispatch(updateImage({ uuid, data }))

            for (var x = 1; ; x++) {
                await this.props.dispatch(getAnalysisStatus(uuid))
                if (this.props.mapAnalysisData.map_analysis_done === true) {
                    setTimeout(() => {
                        this.setState(
                            produce((draft) => {
                                draft.resetting = true
                            })
                        )
                    }, 1000)

                    break
                }
            }
        }

        this.setState({
            checkForChanges: false,
            editPoint: false,
        })
    }

    async resetMap() {
        const { uuid } = this.props.areas
        this.setState(
            produce((draft) => {
                draft.image = ''
                draft.status = 'loading'
            })
        )
        if (this.state.setOriginalImage) {
            this.setState(
                produce((draft) => {
                    draft.restrictedZones = []
                })
            )

            this.props.dispatch(resetImage(uuid))
        } else {
            this.setState(
                produce((draft) => {
                    draft.zones.action = []
                    draft.zones.forbidden = []
                    draft.zones.charging = []
                    draft.zones.resting = []
                    draft.zones.tag = []
                    draft.annotations = []
                })
            )
            this.props.dispatch(resetZones(uuid))
        }

        this.setState({
            checkForChanges: false,
        })

        for (var x = 1; ; x++) {
            await this.props.dispatch(getAnalysisStatus(uuid))
            if (this.props.mapAnalysisData.map_analysis_done === true) {
                setTimeout(() => {
                    this.setState(
                        produce((draft) => {
                            draft.resetting = true
                        })
                    )
                }, 1000)

                break
            }
        }
    }

    getCurrentMousePointer(stage) {
        if (!stage.children[0]?.attrs.x && !stage.children[0]?.attrs.y) {
            return {
                x: (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX(),
                y: (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY(),
            }
        }
        return {
            x:
                (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX() -
                stage.children[0].attrs.x,
            y:
                (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY() -
                stage.children[0].attrs.y,
        }
    }

    resizeMap(zoom) {
        if (zoom === 'increase') {
            this.setState({
                sizes: {
                    ...this.state.sizes,
                    scale: this.state.sizes.scale * 1.2,
                },
            })
        }
        if (zoom === 'decrease')
            this.setState({
                sizes: {
                    ...this.state.sizes,
                    scale: this.state.sizes.scale / 1.2,
                },
            })
    }

    closeModal() {
        this.setState(
            produce((draft) => {
                draft.modal = false
                draft.openModal = false
                draft.clickedZone = {}
            })
        )
    }

    cancelNewZone() {
        this.setState(
            produce((draft) => {
                draft.newZone = {}
                draft.newVersionZone = {}
                draft.modal = false
                draft.openModal = false
                draft.setSquare = false
                draft.setZone = false
                draft.editPoint = false
                draft.isClicked = false
                draft.clickedZone = {}
                draft.checkForChanges = false
                draft.setLine = false
            })
        )
    }
    setNewZone(zone) {
        this.setState(
            produce((draft) => {
                draft.newZone = zone
            })
        )
    }
    setNewVersionZone(zone) {
        this.setState(
            produce((draft) => {
                draft.newVersionZone = zone
            })
        )
    }

    handleNewZone(zone) {
        if (zone.points.length <= 1) return
        const { type, ...rest } = zone
        const newfig = {
            points: rest.points,
            newZone: zone.type,
            id: this.state.figPoints.length + 1,
        }
        this.setState(
            produce((draft) => {
                draft.figPoints.push(newfig)
                draft.newZone = {}
                draft.newVersionZone = {}
                draft.checkForChanges = true
                draft.allChanges.push(newfig)
                if (draft.redoArray.length > 0) draft.redoArray = []
            })
        )
    }

    NewZoneLayer({ zone }) {
        const { sizes, setLine } = this.state
        const { points } = zone
        return (
            <>
                {setLine && (
                    <Line
                        ref={this.newZoneRef}
                        points={[...points?.flat()]}
                        fill={ZONES_STYLES[zone.type].fill}
                        stroke={zone.type === 'forbidden' ? '#F04438' : 'black'}
                        strokeWidth={4 / sizes.scale}
                        opacity={0.7}
                        perfectDrawEnabled={false}
                    />
                )}
            </>
        )
    }

    handlePointer(e) {
        const stage = this.state.stageRef
        const mousePointTo = this.getCurrentMousePointer(stage)
        this.setState({
            cursor: mousePointTo,
        })
    }

    setHoveredZone(zone) {
        this.setState(
            produce((draft) => {
                draft.hoveredZone = zone
            })
        )
    }

    handleStageClick(e) {
        if (!this.state.newVersionZone.type) return
        const stage = this.state.stageRef
        const mousePointTo = this.getCurrentMousePointer(stage)

        e.evt.preventDefault()
        if (e.target.className === 'Text') return
        const pos = Object.values(mousePointTo).map((position) => {
            return Math.floor(position)
        })

        this.setState(
            produce((draft) => {
                draft.newVersionZone.points.push(pos)
            })
        )
    }

    handleCreateZoneOnClick(e) {
        e.evt.preventDefault()
        e.target.getStage().container().style.cursor = 'default'
        if (Object.keys(this.state.newVersionZone).length === 0) return
        if (
            this.state.newVersionZone.zone_type === 'forbidden' ||
            this.state.newVersionZone.zone_type === 'action' ||
            this.state.newVersionZone.zone_type === 'obstacle' ||
            this.state.newVersionZone.zone_type === 'tag'
        )
            return
        const stage = this.state.stageRef
        const { uuid } = this.props.areas
        const { x, y } = this.getCurrentMousePointer(stage)

        const data = {
            ...this.state.newVersionZone,
            x: Math.round(x),
            y: Math.round(y),
            rotation: 0,
            initial_point: [Math.round(x), Math.round(y)],
            allowed_vehicle_categories: [this.state.newVersionZone.allowed_vehicle_categories],
            is_active:
                this.state.newVersionZone.zone_type === 'forbidden' ||
                this.state.newVersionZone.zone_type === 'action' ||
                this.state.newVersionZone.zone_type === 'tag'
                    ? false
                    : true,
        }

        this.props.dispatch(createAreaZones({ uuid, data })).then((res) => {
            this.cancelNewZone()
            this.props.dispatch(getAreaZones({ uuid, type: data.zone_type }))
            this.props.dispatch(getMapStations({ uuid }))
            this.props.dispatch(canRedo(uuid))
            this.props.dispatch(canUndo(uuid))
        })
    }

    handleUpdateOrigin(new_origin_x, new_origin_y) {
        const { uuid } = this.props.areas
        const data = {
            origin_x: new_origin_x.toFixed(2),
            origin_y: new_origin_y.toFixed(2),
        }
        this.props
            .dispatch(
                updateOriginPoint({
                    uuid,
                    data,
                })
            )
            .then(({ error }) => {
                this.setState(
                    produce((draft) => {
                        draft.editPoint = false
                        draft.isSelected = false
                    })
                )
                // this.props.dispatch(getMapAreaDetails(slug))
            })
    }
    handleNewVersionZoneClick(zone, toggle) {
        this.setState(
            produce((draft) => {
                draft.openModal = 'sideIcon'
                draft.clickedZone = zone
                draft.isClicked = true
                draft.setLine = false
                draft.setSquare = false
                draft.setZone = false
                draft.erase = false
                draft.pen = false
                draft.editPoint = false
            })
        )
        toggle()
    }

    handleActionZoneClick(zone, toggle) {
        this.props.dispatch(getZonePresets(zone.uuid)).then(({ payload, error }) => {
            if (error) {
                return
            } else {
                this.setState(
                    produce((draft) => {
                        draft.openModal = 'actionZone'
                        draft.isClicked = true
                        draft.clickedZone = payload.data
                        draft.setLine = false
                        draft.setSquare = false
                        draft.setZone = false
                        draft.erase = false
                        draft.pen = false
                        draft.editPoint = false
                    })
                )
                toggle()
            }
        })
    }

    handleDeleteModalShow(zone) {
        this.setState(
            produce((draft) => {
                draft.openModal = 'removeButton'
                draft.clickedZone = zone
                draft.isClicked = true
                draft.setLine = false
                draft.setSquare = false
                draft.setZone = false
                draft.erase = false
                draft.pen = false
                draft.editPoint = false
            })
        )
    }

    setNotification() {
        this.cancelNewZone()
    }

    handleEditZone(zone) {
        const { dispatch } = this.props
        const areaUuid = this.props.areas.uuid
        const {
            zone_type,
            uuid,
            title,
            allowed_vehicle_categories,
            safety_parameter,
            priority,
            entry_preset,
            exit_preset,
            zone_vehicle_limit,
        } = zone
        const data = {
            uuid,
            title,
            allowed_vehicle_categories: Array.isArray(allowed_vehicle_categories)
                ? allowed_vehicle_categories
                : [allowed_vehicle_categories],
            safety_parameter,
            priority,
            entry_preset,
            exit_preset,
            zone_vehicle_limit,
        }

        dispatch(updateAreaZone({ data, type: zone_type })).then(({ error }) => {
            if (!error) {
                dispatch(getAreaZones({ uuid: areaUuid, type: zone_type }))
                this.props.dispatch(canRedo(areaUuid))
                this.props.dispatch(canUndo(areaUuid))
            }

            this.setState(
                produce((draft) => {
                    draft.chargingId = null
                    draft.restingId = null
                })
            )
            this.cancelNewZone()
        })
    }

    hanldeUpdateMapToRobot() {
        const { dispatch } = this.props
        const { uuid } = this.props.areas
        dispatch(updateMapToRobot(uuid)).then((res) => {
            this.setNotification()
        })
    }

    handleHidePaths() {
        this.setState(
            produce((draft) => {
                draft.hidePaths = !draft.hidePaths
            })
        )
    }

    hasOwn(obj, property) {
        return Object.prototype.hasOwnProperty.call(obj, property)
    }

    handleUndoNew() {
        const areaUuid = this.props.areas.uuid
        this.props.dispatch(doUndoRedo(areaUuid, 'undo')).then((res) => {
            this.props.dispatch(getAreaZonesAll(areaUuid))
            this.props.dispatch(getMapStations({ uuid: areaUuid }))
            this.props.dispatch(getZonesPaths(areaUuid))
            this.props.dispatch(canRedo(areaUuid))
        })
    }

    handleRedoNew() {
        const areaUuid = this.props.areas.uuid
        this.props.dispatch(doUndoRedo(areaUuid, 'redo')).then((res) => {
            this.props.dispatch(getAreaZonesAll(areaUuid))
            this.props.dispatch(getMapStations({ uuid: areaUuid }))
            this.props.dispatch(getZonesPaths(areaUuid))
            this.props.dispatch(canUndo(areaUuid))
        })
    }

    render() {
        const {
            sizes,
            image,
            newZone,
            modal,
            status,
            erase,
            grid,
            setSquare,
            setStatus,
            openModal,
            setLine,
            pen,
            editPoint,
            cursor,
            checkForChanges,
            lines,
            annotations,
            redoArray,
            allChanges,
            figPoints,
            prompt,
            setOriginalImage,
            stageRef,
            newDrawable,
            // stageRotation,
            biggerMapSize,
            smallerMapSize,
            tool,
            isSelected,
            lineId,
            selectedId,
            restrictedZones,
            obstacleId,
            newVersionZone,
            setZone,
            zones,
            forbiddenId,
            tagId,
            actionId,
            chargingId,
            restingId,
            clickedZone,
            isClicked,
            stations,
            zonesPaths,
            devices,
            eraserSize,
            hover,
            hidePaths,
            sizeText,
            sizeOrigin,
            showTagZonesNames,
            currentPage,
            tasks,
            canRedo,
            canUndo,
        } = this.state
        const { NewZoneLayer } = this
        const { original_height, original_width, resolution, origin_x, origin_y } =
            this.props.areaDetails
        const { x, y, width, height } = this.props.aoi
        const stepSize = 1 / resolution
        const xSize = 2000
        const ySize = 2000
        const xSteps = Math.round(xSize / stepSize)
        const ySteps = Math.round(ySize / stepSize)
        const linesA = []
        const linesB = []
        const textA = []
        const textB = []
        const isActive = 'map-create'

        const mousePointerHandle = (e) => {
            const setCursor = (cursorStyle) => {
                e.target.getStage().container().style.cursor = cursorStyle
            }

            const updateHoverState = (hoverText) => {
                this.setState(
                    produce((draft) => {
                        draft.hover = hoverText
                    })
                )
            }

            const handleZoneTypes = ['obstacle', 'tag', 'forbidden', 'action']

            if (pen || e.target.getClassName() === 'Rect') {
                setCursor('pointer')
            } else if (erase) {
                this.handleErase(e)
                setCursor('none')
            } else if (setZone) {
                setCursor('crosshair')
                this.handleHover(e)

                if (handleZoneTypes.includes(newVersionZone.zone_type)) {
                    updateHoverState('Click & drag')
                    this.handleSquare(e)
                } else {
                    updateHoverState('Click to create')
                }
            } else if (setSquare) {
                setCursor('crosshair')
                this.handleHover(e)
                updateHoverState('Click & drag')
                this.handleSquare(e)
            } else if (setLine) {
                if (newZone.type || newVersionZone.type) {
                    this.handleHover(e)
                }
                updateHoverState('Click & drag and then right click to finish')
                return this.handleMouseMove(e)
            } else {
                setCursor('default')
                updateHoverState('')
            }
        }

        const handleUndoRedo = (isUndo) => {
            if (setOriginalImage) {
                const changesArray = isUndo ? [...allChanges] : [...redoArray]
                const undoRedoCheck = changesArray.pop()

                this.setState(
                    produce((draft) => {
                        if (isUndo) {
                            draft.redoArray.push(undoRedoCheck)
                            draft.allChanges.pop()
                        } else {
                            draft.allChanges.push(undoRedoCheck)
                            draft.redoArray.pop()
                        }
                    })
                )

                switch (undoRedoCheck !== undefined && undoRedoCheck !== null) {
                    case this.hasOwn(undoRedoCheck, 'erase'): {
                        const linesArray = isUndo ? [...lines] : [...lines, undoRedoCheck]
                        if (isUndo) linesArray.pop()
                        this.setState({
                            lines: linesArray,
                        })
                        break
                    }
                    case this.hasOwn(undoRedoCheck, 'height'): {
                        const annotationsArray = isUndo
                            ? [...annotations]
                            : [...annotations, undoRedoCheck]
                        if (isUndo) annotationsArray.pop()
                        this.setState({
                            annotations: annotationsArray,
                        })
                        break
                    }
                    case this.hasOwn(undoRedoCheck, 'id'): {
                        const figPointsArray = isUndo
                            ? [...figPoints]
                            : [...figPoints, undoRedoCheck]
                        if (isUndo) figPointsArray.pop()
                        this.setState({
                            figPoints: figPointsArray,
                        })
                        break
                    }
                    default:
                        break
                }
            } else {
                isUndo ? this.handleUndoNew() : this.handleRedoNew()
            }
        }

        const handleUndo = () => handleUndoRedo(true)
        const handleRedo = () => handleUndoRedo(false)

        for (let i = 0; i < xSteps; i++) {
            textA.push(
                <Text
                    x={x + i * stepSize - 2}
                    y={y - 5}
                    text={i * stepSize * resolution}
                    align="right"
                    fontSize={5}
                    fontStyle="bold"
                ></Text>
            )
            linesA.push(
                <>
                    <Line
                        x={x + i * stepSize}
                        y={y}
                        strokeWidth={1}
                        stroke={'grey'}
                        points={[0, 0, 0, ySize]}
                    />
                </>
            )
        }
        for (let i = 0; i < ySteps; i++) {
            textB.push(
                <Text
                    x={x - 5}
                    y={y + i * stepSize - 2}
                    text={i * stepSize * resolution}
                    fontSize={5}
                    fontStyle="bold"
                ></Text>
            )
            linesB.push(
                <>
                    <Line
                        x={x}
                        y={y + i * stepSize}
                        strokeWidth={1}
                        stroke={'grey'}
                        points={[0, 0, xSize, 0]}
                    />
                </>
            )
        }

        const annotationsToDraw = [...annotations, ...newDrawable]

        const ox = Math.abs(Math.floor(origin_x / resolution))
        const oy = original_height - Math.abs(Math.floor(origin_y / resolution))
        const Xcords = ((cursor.x - ox) * resolution).toFixed(2)
        const YCords = -((cursor.y - oy) * resolution).toFixed(2)
        const new_origin_x = cursor.x * resolution
        const new_origin_y = (cursor.y - original_height) * resolution

        // const degToRad = Math.PI / 180

        // const rotatePoint = ({ x, y }, deg) => {
        //     const rcos = Math.cos(deg * degToRad),
        //         rsin = Math.sin(deg * degToRad)
        //     return {
        //         x: x * rcos - y * rsin,
        //         y: y * rcos + x * rsin,
        //     }
        // }
        // const topLeft = {
        //     x: -stageRef?.width() / 2,
        //     y: -stageRef?.height() / 2,
        // }
        // const current = rotatePoint(topLeft, stageRef?.rotation())
        // const rotated = rotatePoint(topLeft, stageRotation)
        // const dx = rotated.x - current.x,
        //     dy = rotated.y - current.y

        // stageRef?.rotation(stageRotation)
        // stageRef?.x(stageRef?.x() + dx)
        // stageRef?.y(stageRef?.y() + dy)

        return (
            <>
                <LeavingPrompt
                    modal={prompt}
                    toggle={this.togglePrompt}
                    checkForChanges={checkForChanges}
                    setCheckForChanges={this.setCheckForChanges}
                    revertMap={this.revertMap}
                    saveMap={this.handleExport}
                    isOriginalImage={setOriginalImage}
                />
                <div className="w-100" style={{ marginTop: '-45px' }} data-testid="map">
                    <h3>Maps</h3>
                </div>
                <Row className="mt-3 position-relative">
                    <Col md={12} className="pb-5">
                        {status !== 'image-error' && (
                            <div ref={this.mapRef}>
                                <Card
                                    className="card-box shadow-none border-0 overflow-auto"
                                    style={{ minHeight: '100vh' }}
                                >
                                    {status === 'loading' ||
                                    image === '' ||
                                    !this.mapRef.current ? (
                                        <LoadingSpinner />
                                    ) : null}
                                    <div
                                        className="d-flex justify-content-between align-items-center pt-2 my-2 mr-3"
                                        data-testid="navbar"
                                    >
                                        <MiniNavbar
                                            slug={this.props.team?.details?.slug}
                                            isActive={isActive}
                                        />
                                        <div className="ml-5">
                                            <BiCurrentLocation size={28} />
                                            <span>
                                                {' '}
                                                {isNaN(Xcords) ? 0 : Xcords}(m) /{' '}
                                                {isNaN(YCords) ? 0 : YCords}(m)
                                                <HiOutlineInformationCircle
                                                    size={18}
                                                    className="mb-3"
                                                    id="info"
                                                />
                                            </span>
                                        </div>
                                        <UncontrolledTooltip
                                            placement="right"
                                            target="info"
                                            style={{
                                                maxWidth: '13rem',
                                            }}
                                            className="custom-map-tooltip"
                                        >
                                            <div>
                                                Resolution: <small>{resolution}</small>-{' '}
                                                <small>
                                                    {original_width} x {original_height}{' '}
                                                </small>{' '}
                                                Grid_size -{' '}
                                                <small className="px-2">{1} Meters</small>{' '}
                                            </div>
                                        </UncontrolledTooltip>
                                    </div>
                                    <CardBody
                                        className="m-0 p-0 mt-3 d-flex overflow-hidden"
                                        data-testid="stage"
                                    >
                                        <Col data-testid="stage2">
                                            <Stage
                                                role="main"
                                                width={sizes.width || 400}
                                                height={sizes.height || 600}
                                                scaleX={sizes.scale ? sizes.scale : 1}
                                                scaleY={sizes.scale ? sizes.scale : 1}
                                                x={-x * sizes?.scale || 1}
                                                y={-y * sizes?.scale + 40 || 1}
                                                ref={(node) => this.onStageRefUpdate(node)}
                                                onClick={(e) => {
                                                    if (setLine) {
                                                        return this.handleStageClick(e)
                                                    }
                                                    return this.handleCreateZoneOnClick(e)
                                                }}
                                                onWheel={(e) => {
                                                    const isScrollingDown = e.evt.deltaY > 0
                                                    if (
                                                        erase &&
                                                        (!e.evt.ctrlKey || !e.evt.metaKey)
                                                    ) {
                                                        let newEraserSize = isScrollingDown
                                                            ? eraserSize + 1
                                                            : eraserSize - 1

                                                        // Limit eraserSize to a minimum value, for example, 10 and for max 50
                                                        newEraserSize = Math.max(newEraserSize, 10)
                                                        newEraserSize = Math.min(newEraserSize, 50)

                                                        this.setState({
                                                            eraserSize: newEraserSize,
                                                        })
                                                    }
                                                    handleWheel(e, stageRef, isScrollingDown)
                                                    this.setState({
                                                        sizeText: 14 / stageRef.scaleX(),
                                                    })
                                                }}
                                                onMouseDown={(e) => this.handleMouseDown(e)}
                                                onMouseMove={(e) => {
                                                    this.handleHover(e)
                                                    mousePointerHandle(e)
                                                }}
                                                onMouseUp={(e) => this.handleMouseUp(e)}
                                                onContextMenu={(e) => {
                                                    e.evt.preventDefault()
                                                    if (newZone.type) this.handleNewZone(newZone)
                                                    if (newVersionZone.type)
                                                        this.handleNewZone(newVersionZone)
                                                    this.setState(
                                                        produce((draft) => {
                                                            draft.setLine = false
                                                            draft.hover = ''
                                                        })
                                                    )
                                                }}
                                            >
                                                <Layer
                                                    md={7}
                                                    draggable={
                                                        !editPoint && !newVersionZone.zone_type
                                                    }
                                                    x={this.state.imageX}
                                                    y={this.state.imageY}
                                                    onDragEnd={(e) => {
                                                        if (setOriginalImage && !editPoint) {
                                                            this.setState(
                                                                produce((draft) => {
                                                                    draft.imageX = e.target.x()
                                                                    draft.imageY = e.target.y()
                                                                })
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <Image
                                                        image={image}
                                                        width={original_width}
                                                        height={original_height}
                                                        ref={this.imageRef}
                                                        onClick={(e) => {
                                                            if (
                                                                e.target === this.imageRef.current
                                                            ) {
                                                                this.setState(
                                                                    produce((draft) => {
                                                                        draft.lineId = null
                                                                        draft.selectedId = null
                                                                        draft.obstacleId = null
                                                                        draft.safeDelId = null
                                                                        draft.forbiddenId = null
                                                                        draft.actionId = null
                                                                        draft.chargingId = null
                                                                        draft.restingId = null
                                                                        draft.tagId = null
                                                                    })
                                                                )
                                                            }
                                                        }}
                                                        onMouseDown={(e) => {
                                                            if (pen) {
                                                                this.handleDrawing(e)
                                                            }
                                                            this.squareDown(e)
                                                        }}
                                                        onMouseMove={(e) => {
                                                            if (pen) {
                                                                this.handleHover(e)
                                                            }
                                                        }}
                                                    />
                                                    {status !== 'loading' &&
                                                        !setOriginalImage &&
                                                        zones.tag.map((zone, i) => {
                                                            return (
                                                                <MapObstacle
                                                                    cursor={cursor}
                                                                    map="editor"
                                                                    stage={stageRef}
                                                                    size={sizeText}
                                                                    editPoint={editPoint}
                                                                    showTagZonesNames={
                                                                        showTagZonesNames
                                                                    }
                                                                    isdraggable={editPoint}
                                                                    isClicked={isSelected}
                                                                    getCurrentMousePointer={
                                                                        this.getCurrentMousePointer
                                                                    }
                                                                    handleClick={
                                                                        this
                                                                            .handleNewVersionZoneClick
                                                                    }
                                                                    toggle={this.toggle}
                                                                    width={width}
                                                                    height={height}
                                                                    biggerMapSize={biggerMapSize}
                                                                    smallerMapSize={smallerMapSize}
                                                                    key={zone.uuid}
                                                                    zone={zone}
                                                                    mouseMove={this.handlePointer}
                                                                    onSelect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.forbiddenId =
                                                                                    null
                                                                                draft.chargingId =
                                                                                    null
                                                                                draft.actionId =
                                                                                    null
                                                                                draft.restingId =
                                                                                    null
                                                                                draft.lineId = null
                                                                                draft.tagId =
                                                                                    zone.uuid
                                                                            })
                                                                        )
                                                                    }}
                                                                    isSelected={zone.uuid === tagId}
                                                                    deselect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.tagId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    onObstacleChange={(
                                                                        newAttrs
                                                                    ) => {
                                                                        const rects =
                                                                            zones.tag.slice()
                                                                        rects[i] = newAttrs
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.zones.tag =
                                                                                    rects
                                                                                draft.tagId = ''
                                                                                draft.checkForChanges = true
                                                                            })
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    {lines.map((line, i) => (
                                                        <Line
                                                            role="line"
                                                            key={i}
                                                            points={line.points}
                                                            stroke="white"
                                                            strokeWidth={
                                                                line.eraserSize
                                                                    ? line.eraserSize >= 15
                                                                        ? line.eraserSize - 10
                                                                        : line.eraserSize - 5
                                                                    : eraserSize
                                                            }
                                                        />
                                                    ))}

                                                    {erase && (
                                                        <MapEditorEraser
                                                            cursor={cursor}
                                                            eraserSize={eraserSize}
                                                            handleErasing={(e) => {
                                                                if (erase) {
                                                                    this.handleDrawing(e)
                                                                }
                                                            }}
                                                            handleMouseMove={(e) => {
                                                                this.handleErase(e)
                                                            }}
                                                        />
                                                    )}

                                                    {grid && textA}
                                                    {grid && linesA}
                                                    {grid && linesB}
                                                    {grid && textB}
                                                    {/* {newZone.type && (
                                                        <NewZoneLayer zone={newZone} />
                                                    )} */}

                                                    {newVersionZone.zone_type && (
                                                        <NewZoneLayer zone={newVersionZone} />
                                                    )}

                                                    {status !== 'loading' &&
                                                        figPoints.map((fig, index) => {
                                                            return (
                                                                <MapObstacle
                                                                    cursor={cursor}
                                                                    map="editor"
                                                                    stage={stageRef}
                                                                    editPoint={editPoint}
                                                                    isClicked={isSelected}
                                                                    size={sizeText}
                                                                    isdraggable={true}
                                                                    getCurrentMousePointer={
                                                                        this.getCurrentMousePointer
                                                                    }
                                                                    width={width}
                                                                    height={height}
                                                                    biggerMapSize={biggerMapSize}
                                                                    smallerMapSize={smallerMapSize}
                                                                    key={fig.id}
                                                                    shapeProps={this.newZoneRef}
                                                                    handleDelete={
                                                                        this.handleKeyDown
                                                                    }
                                                                    handleClick={
                                                                        this
                                                                            .handleNewVersionZoneClick
                                                                    }
                                                                    toggle={this.toggle}
                                                                    zone={fig}
                                                                    mouseMove={this.handlePointer}
                                                                    onSelect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.lineId =
                                                                                    fig.id
                                                                                draft.selectedId =
                                                                                    null
                                                                                draft.obstacleId =
                                                                                    null
                                                                                draft.safeDelId =
                                                                                    null
                                                                                draft.tagId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    isSelected={fig.id === lineId}
                                                                    deselect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.lineId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    onObstacleChange={(
                                                                        newAttrs
                                                                    ) => {
                                                                        const rects =
                                                                            figPoints.slice()
                                                                        rects[index] = newAttrs

                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.figPoints =
                                                                                    rects
                                                                                draft.lineId = ''
                                                                            })
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        })}

                                                    {status !== 'loading'
                                                        ? restrictedZones.map((zone, i) => {
                                                              return (
                                                                  <MapObstacle
                                                                      cursor={cursor}
                                                                      stage={stageRef}
                                                                      map="editor"
                                                                      size={sizeText}
                                                                      editPoint={editPoint}
                                                                      isdraggable={editPoint}
                                                                      isClicked={isSelected}
                                                                      getCurrentMousePointer={
                                                                          this
                                                                              .getCurrentMousePointer
                                                                      }
                                                                      width={width}
                                                                      height={height}
                                                                      biggerMapSize={biggerMapSize}
                                                                      smallerMapSize={
                                                                          smallerMapSize
                                                                      }
                                                                      key={zone?.uuid}
                                                                      zone={zone}
                                                                      handleDelete={
                                                                          this.handleKeyDown
                                                                      }
                                                                      mouseMove={this.handlePointer}
                                                                      onSelect={() => {
                                                                          this.setState(
                                                                              produce((draft) => {
                                                                                  draft.obstacleId =
                                                                                      zone.uuid
                                                                                  draft.selectedId =
                                                                                      null
                                                                                  draft.safeDelId =
                                                                                      null
                                                                                  draft.lineId =
                                                                                      null
                                                                                  draft.tagId = null
                                                                              })
                                                                          )
                                                                      }}
                                                                      isSelected={
                                                                          zone?.uuid === obstacleId
                                                                      }
                                                                      deselect={() => {
                                                                          this.setState(
                                                                              produce((draft) => {
                                                                                  draft.obstacleId =
                                                                                      null
                                                                              })
                                                                          )
                                                                      }}
                                                                      onObstacleChange={(
                                                                          newAttrs
                                                                      ) => {
                                                                          const rects =
                                                                              restrictedZones.slice()
                                                                          rects[i] = newAttrs
                                                                          this.setState(
                                                                              produce((draft) => {
                                                                                  draft.restrictedZones =
                                                                                      rects
                                                                                  draft.obstacleId =
                                                                                      ''
                                                                                  draft.checkForChanges = true
                                                                              })
                                                                          )
                                                                      }}
                                                                  />
                                                              )
                                                          })
                                                        : null}

                                                    {status !== 'loading' &&
                                                        !setOriginalImage &&
                                                        zones?.charging.map((zone, i) => {
                                                            return (
                                                                <MapObstacle
                                                                    cursor={cursor}
                                                                    stage={stageRef}
                                                                    size={sizeText}
                                                                    map="editor"
                                                                    isdraggable={editPoint}
                                                                    isClicked={isSelected}
                                                                    getCurrentMousePointer={
                                                                        this.getCurrentMousePointer
                                                                    }
                                                                    width={width}
                                                                    height={height}
                                                                    biggerMapSize={biggerMapSize}
                                                                    smallerMapSize={smallerMapSize}
                                                                    key={zone?.uuid}
                                                                    zone={zone}
                                                                    editPoint={editPoint}
                                                                    toggle={this.toggle}
                                                                    handleClick={
                                                                        this
                                                                            .handleNewVersionZoneClick
                                                                    }
                                                                    mouseMove={this.handlePointer}
                                                                    onSelect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.chargingId =
                                                                                    zone.uuid
                                                                                draft.forbiddenId =
                                                                                    null
                                                                                draft.actionId =
                                                                                    null
                                                                                draft.restingId =
                                                                                    null
                                                                                draft.lineId = null
                                                                                draft.tagId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    isSelected={
                                                                        zone?.uuid === chargingId
                                                                    }
                                                                    deselect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.chargingId =
                                                                                    null
                                                                            })
                                                                        )
                                                                    }}
                                                                    onObstacleChange={(
                                                                        newAttrs
                                                                    ) => {
                                                                        const rects =
                                                                            zones.charging.slice()
                                                                        rects[i] = newAttrs
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.zones.charging =
                                                                                    rects
                                                                                draft.chargingId =
                                                                                    ''
                                                                                draft.checkForChanges = true
                                                                            })
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    {status !== 'loading' &&
                                                        !setOriginalImage &&
                                                        zones?.resting.map((zone, i) => {
                                                            return (
                                                                <MapObstacle
                                                                    cursor={cursor}
                                                                    stage={stageRef}
                                                                    size={sizeText}
                                                                    map="editor"
                                                                    isdraggable={editPoint}
                                                                    isClicked={isSelected}
                                                                    getCurrentMousePointer={
                                                                        this.getCurrentMousePointer
                                                                    }
                                                                    width={width}
                                                                    height={height}
                                                                    biggerMapSize={biggerMapSize}
                                                                    smallerMapSize={smallerMapSize}
                                                                    key={zone?.uuid}
                                                                    zone={zone}
                                                                    editPoint={editPoint}
                                                                    toggle={this.toggle}
                                                                    handleClick={
                                                                        this
                                                                            .handleNewVersionZoneClick
                                                                    }
                                                                    mouseMove={this.handlePointer}
                                                                    onSelect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.restingId =
                                                                                    zone.uuid
                                                                                draft.forbiddenId =
                                                                                    null
                                                                                draft.actionId =
                                                                                    null
                                                                                draft.chargingId =
                                                                                    null
                                                                                draft.lineId = null
                                                                                draft.tagId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    isSelected={
                                                                        zone?.uuid === restingId
                                                                    }
                                                                    deselect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.restingId =
                                                                                    null
                                                                            })
                                                                        )
                                                                    }}
                                                                    onObstacleChange={(
                                                                        newAttrs
                                                                    ) => {
                                                                        const rects =
                                                                            zones.resting.slice()
                                                                        rects[i] = newAttrs
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.zones.resting =
                                                                                    rects
                                                                                draft.restingId = ''
                                                                                draft.checkForChanges = true
                                                                            })
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        })}

                                                    {status === 'loaded' &&
                                                        !setOriginalImage &&
                                                        !hidePaths &&
                                                        zonesPaths.map((path) => (
                                                            <MapEditorPaths
                                                                key={path?.uuid}
                                                                path={path}
                                                                scale={sizes.scale}
                                                                editPoint={editPoint}
                                                                stations={stations}
                                                                aoi={this.props.aoi}
                                                            />
                                                        ))}

                                                    {status !== 'loading' &&
                                                        !setOriginalImage &&
                                                        zones.action.map((zone, i) => {
                                                            return (
                                                                <MapObstacle
                                                                    cursor={cursor}
                                                                    stage={stageRef}
                                                                    size={sizeText}
                                                                    editPoint={editPoint}
                                                                    map="editor"
                                                                    isdraggable={editPoint}
                                                                    isClicked={isSelected}
                                                                    toggle={this.toggle}
                                                                    getCurrentMousePointer={
                                                                        this.getCurrentMousePointer
                                                                    }
                                                                    handleClick={
                                                                        this.handleActionZoneClick
                                                                    }
                                                                    width={width}
                                                                    height={height}
                                                                    biggerMapSize={biggerMapSize}
                                                                    key={zone?.uuid}
                                                                    zone={zone}
                                                                    mouseMove={this.handlePointer}
                                                                    onSelect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.actionId =
                                                                                    zone.uuid
                                                                                draft.forbiddenId =
                                                                                    null
                                                                                draft.chargingId =
                                                                                    null
                                                                                draft.restingId =
                                                                                    null
                                                                                draft.lineId = null
                                                                                draft.tagId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    isSelected={
                                                                        zone?.uuid === actionId
                                                                    }
                                                                    deselect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.actionId =
                                                                                    null
                                                                            })
                                                                        )
                                                                    }}
                                                                    onObstacleChange={(
                                                                        newAttrs
                                                                    ) => {
                                                                        const rects =
                                                                            zones.action.slice()
                                                                        rects[i] = newAttrs
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.zones.action =
                                                                                    rects
                                                                                draft.actionId = ''
                                                                                draft.checkForChanges = true
                                                                            })
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    {devices &&
                                                        !setOriginalImage &&
                                                        status === 'loaded' &&
                                                        devices.map((device) => (
                                                            <MapEditorDevices
                                                                key={device.uuid}
                                                                device={device}
                                                                aoi={this.props.aoi}
                                                            />
                                                        ))}
                                                    {status !== 'loading' &&
                                                        !setOriginalImage &&
                                                        stations.map((point) => (
                                                            <MapEditorStations
                                                                aoi={this.props.aoi}
                                                                point={point}
                                                                key={point.uuid}
                                                                largestPoint={sizes.largestPoint}
                                                                editPoint={editPoint}
                                                            />
                                                        ))}
                                                    {status !== 'loading' &&
                                                        !setOriginalImage &&
                                                        zones?.forbidden.map((zone, i) => {
                                                            return (
                                                                <MapObstacle
                                                                    cursor={cursor}
                                                                    stage={stageRef}
                                                                    size={sizeText}
                                                                    editPoint={editPoint}
                                                                    map="editor"
                                                                    isdraggable={editPoint}
                                                                    isClicked={isSelected}
                                                                    getCurrentMousePointer={
                                                                        this.getCurrentMousePointer
                                                                    }
                                                                    width={width}
                                                                    height={height}
                                                                    biggerMapSize={biggerMapSize}
                                                                    smallerMapSize={smallerMapSize}
                                                                    key={zone?.uuid}
                                                                    zone={zone}
                                                                    mouseMove={this.handlePointer}
                                                                    onSelect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.forbiddenId =
                                                                                    zone.uuid
                                                                                draft.chargingId =
                                                                                    null
                                                                                draft.actionId =
                                                                                    null
                                                                                draft.restingId =
                                                                                    null
                                                                                draft.lineId = null
                                                                                draft.tagId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    isSelected={
                                                                        zone?.uuid === forbiddenId
                                                                    }
                                                                    deselect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.forbiddenId =
                                                                                    null
                                                                            })
                                                                        )
                                                                    }}
                                                                    onObstacleChange={(
                                                                        newAttrs
                                                                    ) => {
                                                                        const rects =
                                                                            zones.forbidden.slice()
                                                                        rects[i] = newAttrs
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.zones.forbidden =
                                                                                    rects
                                                                                draft.forbiddenId =
                                                                                    ''
                                                                                draft.checkForChanges = true
                                                                            })
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        })}

                                                    {status !== 'loading' &&
                                                        annotationsToDraw.map((annotation, i) => {
                                                            return (
                                                                <Annotation
                                                                    stage={stageRef}
                                                                    tool={tool}
                                                                    cursor={cursor}
                                                                    stageWidth={
                                                                        14 / stageRef?.scaleX()
                                                                    }
                                                                    zone={annotation.newZone}
                                                                    fill={
                                                                        ZONES_STYLES.safe_zone.fill
                                                                    }
                                                                    strokeWidth={4 / sizes.scale}
                                                                    opacity={0.7}
                                                                    width={width}
                                                                    height={height}
                                                                    biggerMapSize={biggerMapSize}
                                                                    smallerMapSize={smallerMapSize}
                                                                    key={annotation.key}
                                                                    shapeProps={annotation}
                                                                    handleDelete={
                                                                        this.handleKeyDown
                                                                    }
                                                                    mouseMove={this.handlePointer}
                                                                    isSelected={
                                                                        annotation.key ===
                                                                        selectedId
                                                                    }
                                                                    onSelect={() => {
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.selectedId =
                                                                                    annotation.key
                                                                                draft.lineId = null
                                                                                draft.obstacleId =
                                                                                    null
                                                                                draft.safeDelId =
                                                                                    null
                                                                                draft.tagId = null
                                                                            })
                                                                        )
                                                                    }}
                                                                    onAnnotationChange={(
                                                                        newAttrs
                                                                    ) => {
                                                                        const rects =
                                                                            this.state.annotations.slice()
                                                                        rects[i] = newAttrs
                                                                        this.setState(
                                                                            produce((draft) => {
                                                                                draft.annotations =
                                                                                    rects
                                                                                draft.selectedId =
                                                                                    ''
                                                                            })
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        })}

                                                    {setZone &&
                                                        newVersionZone.zone_dimensions_in_pixels && (
                                                            <MapPreZone
                                                                pos={cursor}
                                                                width={width}
                                                                height={height}
                                                                zone={newVersionZone}
                                                                biggerMapSize={biggerMapSize}
                                                                smallerMapSize={smallerMapSize}
                                                            />
                                                        )}

                                                    {status !== 'loading' && !setOriginalImage ? (
                                                        <OriginPoint
                                                            ox={ox}
                                                            oy={oy}
                                                            editPoint={editPoint}
                                                            stageWidth={sizeOrigin}
                                                            new_origin_x={new_origin_x}
                                                            new_origin_y={new_origin_y}
                                                            scale={sizes.scale}
                                                            onDragMoveHandle={this.handlePointer}
                                                            onDragEndHandle={
                                                                this.handleUpdateOrigin
                                                            }
                                                        />
                                                    ) : null}
                                                    {hover !== '' && (
                                                        <Text
                                                            x={cursor.x}
                                                            y={cursor.y / 1.2}
                                                            text={this.setHover()}
                                                            fontSize={sizeText}
                                                            fontStyle="bold"
                                                        />
                                                    )}
                                                </Layer>
                                            </Stage>
                                        </Col>
                                        <RenderTopIcons
                                            setOriginalImage={setOriginalImage}
                                            grid={grid}
                                            canRedoState={canRedo}
                                            canUndoState={canUndo}
                                            allChanges={allChanges}
                                            redoArray={redoArray}
                                            hidePaths={hidePaths}
                                            showTagZonesNames={showTagZonesNames}
                                            setShowTagZonesNames={() =>
                                                this.handleShowTagZonesNames()
                                            }
                                            setHidePaths={() => this.handleHidePaths()}
                                            saveButtonClick={() => {
                                                if (setOriginalImage) this.revertMap()
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.openModal = 'topIcon'
                                                        draft.setStatus = 'save'
                                                        draft.selectedId = null
                                                        draft.lineId = null
                                                    })
                                                )
                                                this.toggle()
                                            }}
                                            updateRobots={() => this.hanldeUpdateMapToRobot()}
                                            clearMap={() => {
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.openModal = 'topIcon'
                                                        draft.setStatus = 'reset'
                                                    })
                                                )
                                                this.toggle()
                                            }}
                                            handleUndo={handleUndo}
                                            handleRedo={handleRedo}
                                            eyeGrid={() => {
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.grid = !grid
                                                    })
                                                )
                                            }}
                                            originalMap={() => {
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.status = 'loading'
                                                        draft.setOriginalImage = !setOriginalImage
                                                        draft.erase = false
                                                    })
                                                )
                                                localStorage.setItem(
                                                    'original-image',
                                                    JSON.stringify(!setOriginalImage)
                                                )
                                            }}
                                        />
                                        <RenderRightIcons
                                            setOriginalImage={setOriginalImage}
                                            editPoint={editPoint}
                                            isSelected={isSelected}
                                            erase={erase}
                                            zoomIn={() => handleZoomBtns(stageRef, true)}
                                            zoomOut={() => handleZoomBtns(stageRef, false)}
                                            isDraggable={() => {
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.editPoint = !editPoint
                                                        draft.isSelected = !isSelected
                                                        draft.pen = false
                                                        draft.erase = false
                                                        draft.setLine = false
                                                        draft.setSquare = false
                                                        draft.lineId = null
                                                        draft.selectedId = null
                                                        draft.obstacleId = null
                                                        draft.tagId = null
                                                        draft.forbiddenId = null
                                                        draft.actionId = null
                                                        draft.chargingId = null
                                                        draft.restingId = null
                                                    })
                                                )
                                            }}
                                            drawLine={() => {
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.openModal = 'sideIcon'
                                                        draft.setLine = !setLine
                                                        draft.setSquare = false
                                                        draft.erase = false
                                                        draft.pen = false
                                                        draft.editPoint = true
                                                    })
                                                )
                                                this.setNewVersionZone({
                                                    type: 'obstacle',

                                                    points: [],
                                                })
                                                this.toggle()
                                            }}
                                            addZones={() => {
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.openModal = 'sideIcon'
                                                        draft.setLine = false
                                                        draft.setSquare = false
                                                        draft.setZone = true
                                                        draft.erase = false
                                                        draft.pen = false
                                                    })
                                                )
                                                this.setNewZone({
                                                    type: 'obstacle',
                                                    points: [],
                                                })
                                                this.toggle()
                                            }}
                                            eraseFunc={() => {
                                                this.setState(
                                                    produce((draft) => {
                                                        draft.erase = !erase
                                                        draft.editPoint = true
                                                        draft.pen = false
                                                        draft.setSquare = false
                                                        draft.setLine = false
                                                    })
                                                )
                                            }}
                                            handleKeyDown={this.handleKeyDown}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                        )}
                    </Col>
                </Row>
                <MapEditorModal
                    openModal={openModal}
                    modal={modal}
                    status={setStatus}
                    toggle={this.toggle}
                    saveMap={this.handleExport}
                    resetMap={this.resetMap}
                    areaUuid={this.props.areas.uuid}
                    slug={this.props.match.params.slug}
                    isOriginalImage={setOriginalImage}
                    // update={this.loadData}
                    // mapAnalysisData={this.props.mapAnalysisData}
                    handleAdd={(data) => {
                        if (isClicked) {
                            this.handleEditZone(data)
                        } else {
                            this.setNewVersionZone(data)
                            if (data.zone_type === 'charging' || data.zone_type === 'resting')
                                this.getPreZoneDimensions({
                                    ...data,
                                    allowed_vehicle_categories: [data.allowed_vehicle_categories],
                                })
                        }

                        this.toggle()
                    }}
                    handleDeleteModalShow={this.handleDeleteModalShow}
                    cancelNewZone={this.cancelNewZone}
                    newZone={isClicked ? clickedZone : newVersionZone}
                    isClicked={isClicked}
                    setNotification={this.setNotification}
                    nextPagePresets={tasks.presetsV2?.next}
                    currentPage={currentPage}
                    getPreZoneDimensions={this.getPreZoneDimensions}
                    setCurrentPageNumber={() => currentPage + 1}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        areaDetails: selectors.getTeamMap(state).areaDetails,
        ImagesData: selectors.getTeamMap(state).imagesData,
        user: selectors.getUser(state),
        team: selectors.getTeam(state),
        areas: selectors.getTeamMap(state).areas,
        aoi: selectors.getTeamMap(state).aoi,
        safeZones: selectors.getTeamMap(state).safeZones,
        restrictedZones: selectors.getTeamMap(state).restrictedZones,
        zones: selectors.getTeamMap(state).zones,
        mapAnalysisData: selectors.getTeamMap(state).MapAnalysisStatus,
        stations: selectors.getMapStations(state),
        devices: selectors.getDevicess(state),
        tasks: selectors.getTask(state),
        zonesPaths: selectors.getZonesPaths(state),
        canUndo: selectors.getTeamMap(state).canUndo,
        canRedo: selectors.getTeamMap(state).canRedo,
    }
}

export default compose(withRouter, connect(mapStateToProps))(MapCreate)
