import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Formik } from 'formik'
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { HiOutlineTrash } from 'react-icons/hi'
import {
    createTaskDefinitions,
    getTaskDefinitions,
    updateTaskDefinitions,
} from 'features/dashboard/Task.actionTypes'
import { customInputForm } from 'ui/common'
import DropdownSelect from 'layout-components/Dropdown'
import LoadingSpinner from 'components/utils/LoadingSpinner'

const DefinitionCreation = ({ slug, toggle, toggleRemove, definition = {}, taskStore }) => {
    const dispatch = useDispatch()
    const [fieldCount, setFieldCount] = useState(1)
    const [deviceType, setDeviceType] = useState(definition?.device_type || 'robot')
    const handleSubmit = (outputs, { setSubmitting }) => {
        const { uuid, variable_name, variable_type, required, ...rest } = outputs

        if (uuid) {
            dispatch(updateTaskDefinitions({ uuid, data: rest })).then((res) => {
                setSubmitting(false)
                dispatch(getTaskDefinitions(slug))
                toggle()
            })
        } else {
            const variables = variable_name.map((vn, i) => {
                return { variable_name: vn, variable_type: variable_type[i], required: required[i] ?? true }
            })

            const data = { ...rest, form: { variables } }

            dispatch(createTaskDefinitions({ slug, data })).then((res) => {
                setSubmitting(false)
                dispatch(getTaskDefinitions(slug))
                toggle()
            })
        }
    }

    const addField = (setFieldValue, values) => {
        if (definition.uuid) {
            setFieldValue('form.variables', [
                ...values.form.variables,
                { variable_type: '', variable_name: '' },
            ])
        } else {
            setFieldCount(fieldCount + 1)
        }
    }

    const removeField = (setFieldValue, values, index) => {
        if (definition.uuid) {
            setFieldValue(
                'form.variables',
                values.form.variables.filter((_, i) => i !== index)
            )
        } else {
            setFieldCount((prevCount) => prevCount - 1)
            setFieldValue(
                'variable_type',
                values.variable_type.filter((_, i) => i !== index)
            )
            setFieldValue(
                'variable_name',
                values.variable_name.filter((_, i) => i !== index)
            )
        }
    }

    const handleInputChange = (e, index, setFieldValue) => {
        const value = e.target.value;
        // Regex to check if the input contains only valid characters: letters, digits, '-', '_', or space
        const isValid = /^[\w\-_ ]*$/.test(value);
        if (isValid) {
            definition.uuid
                ? setFieldValue(`form.variables[${index}].variable_name`, value)
                : setFieldValue(`variable_name[${index}]`, value)
        }
    }

    const types = [
        { name: 'string', uuid: 'string' },
        { name: 'integer', uuid: 'integer' },
        { name: 'float', uuid: 'float' },
        { name: 'boolean', uuid: 'boolean' },
    ]

    return taskStore.status === 'loading' ? (
        <div style={{ minHeight: '600px' }}>
            <LoadingSpinner />
            <span>Fetching actions...</span>
        </div>
    ) : (
        <>
            <div
                style={{ marginBottom: '24px' }}
                className={`w-100 d-flex ${
                    definition.uuid ? 'justify-content-end' : 'justify-content-between'
                }`}
            >
                {!definition.uuid && (
                    <img
                        src="/svgs/trigger-actions/add-plus.svg"
                        alt="plus"
                        width="48px"
                        height="48px"
                    />
                )}
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="x"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>
                {definition.uuid ? (
                    <strong className="modals-header">Edit action definition</strong>
                ) : (
                    <strong className="modals-header">New action definition</strong>
                )}
            </h5>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{
                    ...definition,
                    variable_type: [],
                    variable_name: [],
                    required: [],
                }}
            >
                {({ values, isSubmitting, handleSubmit, setFieldValue }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <FormGroup>
                            <Label className="modals-labels" for="displayable_name">
                                Action definition name *
                            </Label>
                            <Field
                                id="displayable_name"
                                component={customInputForm}
                                type="text"
                                className="modals-inputs"
                                name="displayable_name"
                                placeholder="Action definition name"
                                value={values.displayable_name || ''}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels" for="description">
                                Description
                            </Label>
                            <Field
                                id="description"
                                component={customInputForm}
                                type="text"
                                className="modals-inputs"
                                name="description"
                                placeholder="Description"
                                value={values.description || ''}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels" for="device">
                                Device
                            </Label>
                            <DropdownSelect
                                selected={deviceType}
                                setSelected={setDeviceType}
                                options={[
                                    { uuid: 'robot', name: 'robot' },
                                    { uuid: 'iot', name: 'iot' },
                                ]}
                                setFieldValue={setFieldValue}
                                fieldValue="device_type"
                            />
                            {/* <Field
                                id="device"
                                component={customInputForm}
                                type="select"
                                className="modals-inputs"
                                name="device_type"
                                value={values.device_type || ''}
                            >
                                <option value="robot">Robot</option>
                                <option value="iot">IoT</option>
                            </Field> */}
                        </FormGroup>
                        {definition.uuid ? (
                            <>
                                {values.form?.variables.map((variable, index) => (
                                    <div key={index}>
                                        <FormGroup>
                                            <Label
                                                className="modals-labels"
                                                for={`variable_type_${index}`}
                                            >
                                                Variable type {index === 0 ? null : index + 1}
                                            </Label>
                                            <div className="d-flex">
                                                <DropdownSelect
                                                    selected={
                                                        values.form.variables[index].variable_type
                                                    }
                                                    options={types}
                                                    index={index}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue={`form.variables[${index}].variable_type`}
                                                />
                                                {index > 0 && (
                                                    <img
                                                        src="/svgs/close-icon/x-dark-default.svg"
                                                        alt="x"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                            removeField(
                                                                setFieldValue,
                                                                values,
                                                                index
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                className="modals-labels"
                                                for={`variable_name_${index}`}
                                            >
                                                {' '}
                                                Variable name {index === 0 ? null : index + 1}
                                            </Label>
                                            <Field
                                                component={customInputForm}
                                                type="text"
                                                required
                                                name={`form.variables[${index}].variable_name`}
                                                placeholder="Variable name"
                                                value={
                                                    values.form?.variables[index].variable_name ||
                                                    ''
                                                }
                                                className="modals-inputs"
                                                onChange={(e) =>
                                                    handleInputChange(e, index, setFieldValue)
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup className="ml-2" check>
                                            <Input
                                                type="checkbox"
                                                id={`required_${index}`}
                                                checked={
                                                    values.form?.variables[index].required
                                                }
                                                name={`form.variables[${index}].required`}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        `form.variables[${index}].required`,
                                                        e.target.checked
                                                    )
                                                }}
                                            />
                                            <Label className="modals-labels">Required</Label>
                                        </FormGroup>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                {[...Array(fieldCount)].map((_, index) => (
                                    <div key={index}>
                                        <FormGroup>
                                            <Label
                                                className="modals-labels"
                                                for={`variable_type_${index}`}
                                            >
                                                Variable type {index === 0 ? null : index + 1} *
                                            </Label>
                                            <div className="d-flex">
                                                <DropdownSelect
                                                    selected={values.variable_type[index]}
                                                    options={types}
                                                    index={index}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue={`variable_type[${index}]`}
                                                />
                                                {index > 0 && (
                                                    <img
                                                        src="/svgs/close-icon/x-dark-default.svg"
                                                        alt="x"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                            removeField(
                                                                setFieldValue,
                                                                values,
                                                                index
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                className="modals-labels"
                                                for={`variable_name_${index}`}
                                            >
                                                {' '}
                                                Variable name {index === 0 ? null : index + 1} *
                                            </Label>
                                            <Field
                                                id={`variable_name_${index}`}
                                                component={customInputForm}
                                                type="text"
                                                name={`variable_name[${index}]`}
                                                placeholder="Variable name"
                                                value={values.variable_name[index] || ''}
                                                className="modals-inputs"
                                                onChange={(e) =>
                                                    handleInputChange(e, index, setFieldValue)
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup className="ml-2" check>
                                            <Input
                                                id={`required_${index}`}
                                                type="checkbox"
                                                name={`required[${index}]`}
                                                checked={values?.required[index] ?? true}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        `required[${index}]`,
                                                        e.target.checked ? true : false
                                                    )
                                                }}
                                            />
                                            <Label className="modals-labels">Required</Label>
                                        </FormGroup>
                                    </div>
                                ))}
                            </>
                        )}
                        <div
                            style={{
                                marginTop: '24px',
                                border: '1px dashed var(--grey-100, #e7e9ec)',
                                cursor: 'pointer',
                            }}
                            className="modals-inputs"
                            onClick={() => addField(setFieldValue, values)}
                        >
                            <img src="/svgs/trigger-actions/add-remove.svg" alt="add-circle" />
                            <span className="ml-2" style={{ color: '#9DA3B0' }}>
                                Add variable
                            </span>
                        </div>

                        <div
                            style={{ marginTop: '24px' }}
                            className={`w-100 d-flex align-items-center ${
                                definition.uuid ? 'justify-content-between' : 'justify-content-end'
                            } align-items-center`}
                        >
                            {definition.uuid ? (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        toggleRemove()
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete definition
                                </Button>
                            ) : null}
                            <div className="d-flex">
                                <Button
                                    color="white"
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                    onClick={() => toggle()}
                                >
                                    Discard
                                </Button>
                                <Button type="submit" className="save-btn-modal modals-new-btns">
                                    Save
                                    {isSubmitting && <Spinner color="white" size="sm ml-2" />}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default DefinitionCreation
