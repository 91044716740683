import React from 'react'
import { Rect } from 'react-konva'

const MapPreZone = ({ pos, zone, width, height, smallerMapSize, biggerMapSize }) => {
    const zoneTypeColor = () => {
        switch (true) {
            case zone.zone_type === 'charging':
                return '#FFF5DF'
            case zone.zone_type === 'resting':
                return '#EFF8FF'
            default:
        }
    }

    const zoneBorderColor = () => {
        switch (zone.zone_type) {
            case 'charging':
                return '#FFD67C'
            case 'resting':
                return '#53B1FD'
            default:
        }
    }

    if (Object.values(zone).length === 0) return null

    return (
        <Rect
            x={pos.x - zone?.zone_dimensions_in_pixels[0] / 2}
            y={pos.y - zone?.zone_dimensions_in_pixels[1] / 2}
            stroke={zoneBorderColor()}
            width={zone?.zone_dimensions_in_pixels[0]}
            height={zone?.zone_dimensions_in_pixels[1]}
            strokeWidth={
                width * height < smallerMapSize
                    ? 0.4
                    : width * height > biggerMapSize
                    ? 7
                    : width < 200
                    ? 0.4
                    : 1.2
            }
            fill={zoneTypeColor()}
            opacity={0.2}
        />
    )
}

export default MapPreZone
