import React, { useState } from 'react'
import { Button, Label, FormGroup, Form, Modal, ModalBody } from 'reactstrap'
import { HiOutlineTrash } from 'react-icons/hi'
import { customInputForm } from 'ui/common'
import { Field, Formik } from 'formik'

const TaskModalV2 = ({ task, toggle, modal, handleFail, canCancelTask }) => {
    const [smallModal, setSmallModal] = useState(false)
    const toggleSmall = () => {
        setSmallModal(!smallModal)
    }

    const taskStatus = () => {
        switch (task?.status) {
            case 'completed':
            case 'in_progress':
                return (
                    <span
                        className="ml-2 d-flex justify-content-center align-items-center"
                        style={{
                            backgroundColor: '#ECFDF3',
                            padding: '0px 8px',
                            borderRadius: '16px',
                            color: '#05603A',
                        }}
                    >
                        {task?.status?.charAt(0).toUpperCase() + task?.status?.slice(1)}
                    </span>
                )
            case undefined:
                return null
            default:
                return (
                    <span
                        className="ml-2 d-flex justify-content-center align-items-center"
                        style={{
                            backgroundColor: '#F4F4F9',
                            padding: '0px 8px',
                            borderRadius: '16px',
                            color: '#3A3E66',
                        }}
                    >
                        {task?.status?.charAt(0).toUpperCase() + task?.status?.slice(1)}
                    </span>
                )
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="new-modals">
            <ModalBody>
                <div
                    style={{ marginBottom: '24px' }}
                    className={`w-100 d-flex justify-content-between`}
                >
                    <img
                        src="/svgs/modal-icons/modal-station-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    {/* <img 
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                /> */}
                </div>
                <div className="d-flex justify-content-start align-items-start">
                    <h5>
                        <strong className="modals-header">Mission details</strong>
                    </h5>
                    {taskStatus()}
                </div>
                <Formik initialValues={task}>
                    {({ values }) => (
                        <Form>
                            <FormGroup>
                                <Label className="modals-labels" for="name">
                                    Name
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    id="name"
                                    type="text"
                                    name="number"
                                    component={customInputForm}
                                    value={
                                        task?.task_preset ? values.name || '' : values?.number || ''
                                    }
                                ></Field>
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels" for="description">
                                    Description
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    id="description"
                                    type="text"
                                    name="description"
                                    component={customInputForm}
                                    value={values?.description || ''}
                                ></Field>
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels" for="definition">
                                    Actions
                                </Label>
                                <Field
                                    className="modals-inputs"
                                    id="definition"
                                    type="hidden"
                                    name="sub.action.definition_information.displayable_name"
                                    component={customInputForm}
                                />
                                {task?.task_preset
                                    ? values?.task_preset?.subtasks.map((sub, i) => (
                                          <div
                                              key={sub.uuid}
                                              className="p-3 d-flex justify-content-between align-items-center"
                                          >
                                              <div className="d-grid row-2">
                                                  <span className="mr-3">{i + 1}.</span>
                                                  <span>
                                                      {
                                                          sub.action.definition_information
                                                              .displayable_name
                                                      }{' '}
                                                      {sub.action.point_information?.title
                                                          ? ' -'
                                                          : null}{' '}
                                                      {sub.action.definition_information.slug ===
                                                      'follow_path'
                                                          ? sub.action.path
                                                                .map((p) => p.title)
                                                                .join(' -> ')
                                                          : sub.action?.point_information?.title}
                                                  </span>
                                              </div>
                                              {/* <div className="d-grid row-2">
                                        <HiPencilAlt
                                            className="mr-2 add-pointer"
                                            size={19}
                                            color="gray"
                                        />
                                        <HiOutlineTrash className="add-pointer" size={20} />
                                    </div> */}
                                          </div>
                                      ))
                                    : values?.subtasks.map((sub, i) => (
                                          <div
                                              key={sub.uuid}
                                              className="p-3 d-flex justify-content-between align-items-center"
                                          >
                                              <div className="d-grid row-2">
                                                  <span className="mr-3">{i + 1}.</span>
                                                  <span>
                                                      {
                                                          sub.action.definition_information
                                                              .displayable_name
                                                      }
                                                      {sub.action?.point_information?.title
                                                          ? ' -'
                                                          : null}{' '}
                                                      {sub.action.definition_information.slug ===
                                                      'follow_path'
                                                          ? sub.action.path
                                                                .map((p) => p.title)
                                                                .join(' -> ')
                                                          : sub.action?.point_information?.title}
                                                  </span>
                                              </div>
                                              {/* <div className="d-grid row-2">
                                        <HiPencilAlt
                                            className="mr-2 add-pointer"
                                            size={19}
                                            color="gray"
                                        />
                                        <HiOutlineTrash className="add-pointer" size={20} />
                                    </div> */}
                                          </div>
                                      ))}
                            </FormGroup>

                            <div
                                style={{ marginTop: '24px' }}
                                className={`d-flex w-100 ${
                                    canCancelTask()
                                        ? 'justify-content-between'
                                        : 'justify-content-end'
                                } align-items-center`}
                            >
                                {canCancelTask() && (
                                    <Button
                                        color="none"
                                        onClick={() => setSmallModal(true)}
                                        className="modals-delete-btn"
                                    >
                                        <HiOutlineTrash size={20} className="mr-1" />
                                        Delete mission
                                    </Button>
                                )}

                                <Button onClick={toggle} className="save-btn-modal modals-new-btns">
                                    Close window
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

                <Modal isOpen={smallModal} toggle={toggleSmall} centered className="new-modals">
                    <ModalBody>
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{ marginBottom: '24px' }}
                        >
                            <img
                                src="/svgs/modal-icons/trash-icon.svg"
                                alt="obstacle-icon"
                                width="48px"
                                height="48px"
                            />
                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="obstacle-icon"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggle}
                            />
                        </div>
                        <span>
                            Are you sure you want to delete{' '}
                            {task?.task_preset ? task?.name : task?.number}? This action cannot be
                            undone.
                        </span>

                        <div
                            style={{ marginTop: '24px' }}
                            className={`w-100 d-flex justify-content-between align-items-center`}
                        >
                            <Button
                                className="prompt-modal-footer-decline-button"
                                href="#"
                                onClick={() => {
                                    toggleSmall()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="prompt-modal-footer-accept-button-danger"
                                onClick={() => {
                                    toggleSmall()
                                    handleFail(task)
                                }}
                                data-testid="delete"
                            >
                                Delete
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </ModalBody>
        </Modal>
    )
}

export default TaskModalV2
