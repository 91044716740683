import React, { useState } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import EyeShow from 'layout-components/Eye/EyeShow'

export const renderDifferentActions = (hovered, hoveredIcon, clicked, clickedIcon, icon, alt) => {
    switch (true) {
        case hovered:
            return <img src={hoveredIcon} alt={alt} width={28} height={28} />
        case clicked:
            return <img src={clickedIcon} alt={alt} width={28} height={28} />
        default:
            return <img src={icon} alt={alt} width={28} height={28} />
    }
}

export const RenderTopIcons = ({
    setOriginalImage,
    grid,
    canRedoState,
    canUndoState,
    allChanges,
    redoArray,
    saveButtonClick,
    updateRobots,
    clearMap,
    handleUndo,
    handleRedo,
    eyeGrid,
    originalMap,
    hidePaths,
    setHidePaths,
    showTagZonesNames,
    setShowTagZonesNames,
}) => {
    const [buttonStates, setButtonStates] = useState({
        save: { hovered: false, clicked: false },
        update: { hovered: false, clicked: false },
        reset: { hovered: false, clicked: false },
        undo: { hovered: false, clicked: false },
        redo: { hovered: false, clicked: false },
        originalMap: { hovered: false },
        isEyeHovered: false,
    })
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)

    const handleButtonState = ({ button, eventType, value }) => {
        if (button === 'isEyeHovered') {
            setButtonStates({ ...buttonStates, [button]: value })
        } else {
            setButtonStates({
                ...buttonStates,
                [button]: { ...buttonStates[button], [eventType]: value },
            })
        }
    }

    const handleButtonEvent = ({ button, eventType, value }) => {
        return () => {
            handleButtonState({ button, eventType, value })
        }
    }

    return (
        <div
            className="d-flex"
            style={{ position: 'fixed', top: '10rem', right: '1rem' }}
            data-testid="topicons"
        >
            <Button
                data-testid="savebtn"
                size="sm"
                id="save-btn"
                className="p-0 iconBoader"
                onClick={() => saveButtonClick()}
                onMouseDown={handleButtonEvent({
                    button: 'save',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'save',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'save',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'save',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.save.hovered,
                    '/svgs/map-editor/save-hover.svg',
                    buttonStates.save.clicked,
                    '/svgs/map-editor/save-clicked.svg',
                    '/svgs/map-editor/save.svg',
                    'save'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="save-btn"
                style={{
                    maxWidth: '13rem',
                    textAlign: 'center',
                }}
                className='custom-map-tooltip'
            >
                Save current changes
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="update-btn"
                className="p-0 ml-1 iconBoader"
                onClick={() => updateRobots()}
                onMouseDown={handleButtonEvent({
                    button: 'update',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'update',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'update',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'update',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.update.hovered,
                    '/svgs/map-editor/update-map-hover.svg',
                    buttonStates.update.clicked,
                    '/svgs/map-editor/update-map-clicked.svg',
                    '/svgs/map-editor/update-map.svg',
                    'update'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="update-btn"
                style={{
                    maxWidth: '14rem',
                    textAlign: 'center'
                }}  
                className='custom-map-tooltip'
            >
                Send map updates to robot
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="reset-btn"
                className="p-0 mx-1 iconBoader"
                onClick={clearMap}
                onMouseDown={handleButtonEvent({
                    button: 'reset',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'reset',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'reset',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'reset',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.reset.hovered,
                    '/svgs/map-editor/reset-hover.svg',
                    buttonStates.reset.clicked,
                    '/svgs/map-editor/reset-clicked.svg',
                    '/svgs/map-editor/reset.svg',
                    'reset'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="reset-btn"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                Clear map
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="undo-btn"
                className="p-0 iconBoader"
                onClick={handleUndo}
                disabled={setOriginalImage ? allChanges.length === 0 : !canUndoState}
                onMouseDown={handleButtonEvent({
                    button: 'undo',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'undo',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'undo',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'undo',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.undo.hovered,
                    '/svgs/map-editor/undo-hover.svg',
                    buttonStates.undo.clicked,
                    '/svgs/map-editor/undo-clicked.svg',
                    '/svgs/map-editor/undo.svg',
                    'undo'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="undo-btn"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                Undo
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="redo-btn"
                className="p-0 mx-1 iconBoader"
                onClick={handleRedo}
                disabled={setOriginalImage ? redoArray.length === 0 : !canRedoState}
                onMouseDown={handleButtonEvent({
                    button: 'redo',
                    eventType: 'clicked',
                    value: true,
                })}
                onMouseUp={handleButtonEvent({
                    button: 'redo',
                    eventType: 'clicked',
                    value: false,
                })}
                onMouseEnter={handleButtonEvent({
                    button: 'redo',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'redo',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.redo.hovered,
                    '/svgs/map-editor/redo-hover.svg',
                    buttonStates.redo.clicked,
                    '/svgs/map-editor/redo-clicked.svg',
                    '/svgs/map-editor/redo.svg',
                    'redo'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="redo-btn"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                Redo
            </UncontrolledTooltip>

            <EyeShow
                eyeGrid={eyeGrid}
                grid={grid}
                dropdownOpen={dropdownOpen}
                toggle={dropdownToggle}
                hidePaths={hidePaths}
                setHidePaths={setHidePaths}
                buttonState={buttonStates}
                place="map_editor"
                handleButtonEvent={handleButtonEvent}
                showTagZonesNames={showTagZonesNames}
                setShowTagZonesNames={setShowTagZonesNames}
            />

            <Button
                size="sm"
                id="original-map"
                className="p-0 mx-1 iconBoader"
                onClick={originalMap}
                onMouseEnter={handleButtonEvent({
                    button: 'originalMap',
                    eventType: 'hovered',
                    value: true,
                })}
                onMouseLeave={handleButtonEvent({
                    button: 'originalMap',
                    eventType: 'hovered',
                    value: false,
                })}
            >
                {renderDifferentActions(
                    buttonStates.originalMap.hovered,
                    '/svgs/map-editor/map-hover.svg',
                    setOriginalImage,
                    '/svgs/map-editor/map-clicked.svg',
                    '/svgs/map-editor/map.svg',
                    'map'
                )}
            </Button>
            <UncontrolledTooltip
                placement="bottom"
                target="original-map"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                {!setOriginalImage ? <>Original map</> : <>SVG map</>}
            </UncontrolledTooltip>
        </div>
    )
}

export const RenderRightIcons = ({
    setOriginalImage,
    editPoint,
    erase,
    isSelected,
    zoomIn,
    zoomOut,
    isDraggable,
    drawLine,
    addZones,
    eraseFunc,
    handleKeyDown,
}) => {
    const [buttonStates, setButtonStates] = useState({
        zoomIn: { hovered: false, clicked: false },
        zoomOut: { hovered: false, clicked: false },
        hidePaths: { hovered: false, clicked: false },
        drag: { hovered: false },
        drawLine: { hovered: false, clicked: false },
        rectangle: { hovered: false, clicked: false },
        eraser: { hovered: false },
        trash: { hovered: false, clicked: false },
    })

    const handleButtonState = (button, eventType, value) => {
        setButtonStates({
            ...buttonStates,
            [button]: { ...buttonStates[button], [eventType]: value },
        })
    }

    const handleButtonEvent = (button, eventType, value) => {
        return () => {
            handleButtonState(button, eventType, value)
        }
    }

    return (
        <div
            className="d-flex flex-column"
            style={{ position: 'fixed', top: '12.5rem', right: '1.2rem' }}
            data-testid="righticons"
        >
            <Button
                size="sm"
                id="zoomin-btn"
                onClick={zoomIn}
                onMouseDown={handleButtonEvent('zoomIn', 'clicked', true)}
                onMouseUp={handleButtonEvent('zoomIn', 'clicked', false)}
                onMouseEnter={handleButtonEvent('zoomIn', 'hovered', true)}
                onMouseLeave={handleButtonEvent('zoomIn', 'hovered', false)}
                className="mb-2 p-0 iconBoader"
            >
                {renderDifferentActions(
                    buttonStates.zoomIn.hovered,
                    '/svgs/map-editor/zoom-in-hover.svg',
                    buttonStates.zoomIn.clicked,
                    '/svgs/map-editor/zoom-in-clicked.svg',
                    '/svgs/map-editor/zoom-in.svg',
                    'zoom-in'
                )}
            </Button>

            <UncontrolledTooltip
                placement="auto"
                target="zoomin-btn"
                style={{
                    maxWidth: '13rem',
                    textAlign: 'center'
                }}
                className='custom-map-tooltip'
            >
                Zoom in
                <br />
                (CMD/Ctrl + scroll up)
            </UncontrolledTooltip>

            <Button
                size="sm"
                id="zoomout-btn"
                className="mb-2 p-0 iconBoader"
                onClick={zoomOut}
                onMouseDown={handleButtonEvent('zoomOut', 'clicked', true)}
                onMouseUp={handleButtonEvent('zoomOut', 'clicked', false)}
                onMouseEnter={handleButtonEvent('zoomOut', 'hovered', true)}
                onMouseLeave={handleButtonEvent('zoomOut', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.zoomOut.hovered,
                    '/svgs/map-editor/zoom-out-hover.svg',
                    buttonStates.zoomOut.clicked,
                    '/svgs/map-editor/zoom-out-clicked.svg',
                    '/svgs/map-editor/zoom-out.svg',
                    'zoom-out'
                )}
            </Button>

            <UncontrolledTooltip
                placement="auto"
                target="zoomout-btn"
                style={{
                    maxWidth: '13rem',
                    textAlign: 'center'
                }}
                className='custom-map-tooltip'
            >
                Zoom out
                <br />
                (CMD/Ctrl + scroll down)
            </UncontrolledTooltip>

            {!setOriginalImage ? (
                <Button
                    size="sm"
                    id="draggable"
                    className="p-0 mb-2 iconBoader"
                    onClick={isDraggable}
                    onMouseEnter={() => handleButtonState('drag', 'hovered', true)}
                    onMouseLeave={() => handleButtonState('drag', 'hovered', false)}
                >
                    {renderDifferentActions(
                        buttonStates.drag.hovered,
                        '/svgs/map-editor/drag-hover.svg',
                        editPoint && isSelected,
                        '/svgs/map-editor/drag-selected.svg',
                        '/svgs/map-editor/drag.svg',
                        'drag'
                    )}
                    <UncontrolledTooltip
                        placement="auto"
                        target="draggable"
                        style={{
                            maxWidth: '13rem',
                            textAlign: 'center',
                        }}
                        className='custom-map-tooltip'
                    >
                        {editPoint ? 'Disable draggable' : 'Enable draggable'}
                    </UncontrolledTooltip>
                </Button>
            ) : null}

            {setOriginalImage ? (
                <>
                    <Button
                        size="sm"
                        id="line-btn"
                        className="p-0 mb-2 iconBoader"
                        onClick={drawLine}
                        onMouseDown={handleButtonEvent('drawLine', 'clicked', true)}
                        onMouseUp={handleButtonEvent('drawLine', 'clicked', false)}
                        onMouseEnter={handleButtonEvent('drawLine', 'hovered', true)}
                        onMouseLeave={handleButtonEvent('drawLine', 'hovered', false)}
                    >
                        {renderDifferentActions(
                            buttonStates.drawLine.hovered,
                            '/svgs/map-editor/line-selected.svg',
                            buttonStates.drawLine.clicked,
                            '/svgs/map-editor/line-selected.svg',
                            '/svgs/map-editor/line.svg',
                            'line'
                        )}
                    </Button>
                    <UncontrolledTooltip
                        placement="auto"
                        target="line-btn"
                        sstyle={{
                            maxWidth: '13rem',
                        }}
                        className='custom-map-tooltip'
                    >
                        Draw lines for obstacles/safezones on map
                    </UncontrolledTooltip>
                </>
            ) : null}

            <Button
                size="sm"
                id="shape-btn"
                className="p-0 mb-2 iconBoader"
                onClick={addZones}
                onMouseDown={handleButtonEvent('rectangle', 'clicked', true)}
                onMouseUp={handleButtonEvent('rectangle', 'clicked', false)}
                onMouseEnter={handleButtonEvent('rectangle', 'hovered', true)}
                onMouseLeave={handleButtonEvent('rectangle', 'hovered', false)}
                data-testid="addzone"
            >
                {renderDifferentActions(
                    buttonStates.rectangle.hovered,
                    '/svgs/map-editor/rectangle-selected.svg',
                    buttonStates.rectangle.clicked,
                    '/svgs/map-editor/rectangle-selected.svg',
                    '/svgs/map-editor/rectangle.svg',
                    'rectangle'
                )}
            </Button>

            <UncontrolledTooltip
                placement="auto"
                target="shape-btn"
                style={{
                    maxWidth: '13rem',
                }}
                className='custom-map-tooltip'
            >
                {setOriginalImage ? <>Add obstacles on map</> : <>Add zones on map</>}
            </UncontrolledTooltip>

            {setOriginalImage && (
                <>
                    <Button
                        size="sm"
                        id="eraser-btn"
                        className="p-0 mb-2 iconBoader"
                        onClick={eraseFunc}
                        onMouseEnter={() => handleButtonState('eraser', 'hovered', true)}
                        onMouseLeave={() => handleButtonState('eraser', 'hovered', false)}
                    >
                        {renderDifferentActions(
                            buttonStates.eraser.hovered,
                            '/svgs/map-editor/eraser-hover.svg',
                            erase,
                            '/svgs/map-editor/eraser-selected.svg',
                            '/svgs/map-editor/eraser.svg',
                            'eraser'
                        )}
                    </Button>
                    <UncontrolledTooltip
                        placement="auto"
                        target="eraser-btn"
                        sstyle={{
                            maxWidth: '10rem'
                        }}
                        className='custom-map-tooltip'
                    >
                        Eraser
                    </UncontrolledTooltip>
                </>
            )}

            <Button
                size="sm"
                id="trash-btn"
                className="mb-2 p-0 iconBoader"
                onClick={handleKeyDown}
                onMouseDown={handleButtonEvent('trash', 'clicked', true)}
                onMouseUp={handleButtonEvent('trash', 'clicked', false)}
                onMouseEnter={handleButtonEvent('trash', 'hovered', true)}
                onMouseLeave={handleButtonEvent('trash', 'hovered', false)}
            >
                {renderDifferentActions(
                    buttonStates.trash.hovered,
                    '/svgs/map-editor/trash-hover.svg',
                    buttonStates.trash.clicked,
                    '/svgs/map-editor/trash-selected.svg',
                    '/svgs/map-editor/trash.svg',
                    'trash'
                )}
            </Button>

            <UncontrolledTooltip
                placement="auto"
                target="trash-btn"
                style={{
                    maxWidth: '10rem'
                }}
                className='custom-map-tooltip'
            >
                Select an obstacle & delete
            </UncontrolledTooltip>
        </div>
    )
}
