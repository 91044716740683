import React from 'react'
export const schedulesList = [
    {
        field: 'title',
        headerName: 'Name',
        sortable: true,
        unSortIcon: true,
    },
    {
        field: 'execute_at',
        headerName: 'Execute',
        valueFormatter: (p) => p.data.execute_at,
    },
    {
        field: 'details',
        headerName: 'Details',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <span>{params.data.details}</span>
        },
    },
    {
        field: 'enabled',
        headerName: 'Enabled',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return params.data.enabled ? 'true' : 'false'
        },
    },
    {
        field: 'offline',
        headerName: 'Offline',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return params.data.offline ? 'true' : 'false'
        },
    },
    {
        field: 'update',
        sort: false,
        headerName: '',
        cellClass: 'action-style d-flex justify-content-center align-items-center',
        maxWidth: 100,
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.update}</div>
        },
    },
]

export const presetsColumns = [
    {
        field: 'title',
        headerName: 'Name',
        sortable: true,
        unSortIcon: true,
    },
    {
        field: 'created_at',
        headerName: 'Created',
    },
    {
        field: 'details',
        headerName: 'Details',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <span>{params.data.details}</span>
        },
    },
    {
        field: 'update',
        sort: false,
        headerName: '',
        cellClass: 'action-style d-flex justify-content-center align-items-center',
        maxWidth: 100,
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.update}</div>
        },
    },
]

export const actionsPresetsColumns = [
    {
        field: 'title',
        headerName: 'Name',
        sortable: true,
        unSortIcon: true,
    },
    {
        field: 'type',
        headerName: 'Type',
    },
    {
        field: 'details',
        headerName: 'Details',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.details}</div>
        },
    },

    {
        field: 'update',
        sort: false,
        headerName: '',
        cellClass: 'action-style d-flex justify-content-center align-items-center',
        maxWidth: 100,
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.update}</div>
        },
    },
]

export const definitionList = [
    {
        field: 'title',
        headerName: 'Name',
        sortable: true,
        unSortIcon: true,
    },
    {
        field: 'type',
        headerName: 'Type',
    },
    {
        field: 'description',
        headerName: 'Description',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.description}</div>
        },
    },
    {
        field: 'variables',
        headerName: 'Variables',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.variables}</div>
        },
        minWidth: 300,
    },
    {
        field: 'update',
        headerName: '',
        cellClass: 'action-style d-flex justify-content-center align-items-center',
        maxWidth: 100,
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.update}</div>
        },
    },
]

export const triggersColumns = [
    {
        field: 'title',
        headerName: 'Name',
        sortable: true,
        unSortIcon: true,
    },
    {
        field: 'url',
        headerName: 'URL',
        tooltipField: 'url', // Show full content in a tooltip
        cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
        field: 'action',
        headerName: 'Action',
    },
    {
        field: 'ip_whitelist',
        headerName: 'ip_whitelist',
    },
    {
        field: 'initialized',
        headerName: 'Initialized',
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return params.data.initialized ? 'true' : 'false'
        },
    },

    {
        field: 'update',
        sort: false,
        headerName: '',
        cellClass: 'action-style d-flex justify-content-center align-items-center',
        maxWidth: 100,
        valueFormatter: (params) => {
            return params.value
        },
        cellRenderer: (params) => {
            return <div>{params.data.update}</div>
        },
    },
]
