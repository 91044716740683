import { getDevices, udpateDevices } from './RosSetup.actionTypes'

export const actionTypes = {
    FINALIZE: 'FINALIZE',
    FINALIZE_FAIL: 'FINALIZE_FAIL',
    FINALIZE_SUCCESS: 'FINALIZE_SUCCESS',
    OUTDOOR_FINALIZE: 'OUTDOOR_FINALIZE',
    OUTDOOR_FINALIZE_FAIL: 'OUTDOOR_FINALIZE_FAIL',
    OUTDOOR_FINALIZE_SUCCESS: 'OUTDOOR_FINALIZE_SUCCESS',
    GET_OUTDOOR_MAP_PATHS: 'GET_OUTDOOR_MAP_PATHS',
    GET_OUTDOOR_MAP_PATHS_FAIL: 'GET_OUTDOOR_MAP_PATHS_FAIL',
    GET_OUTDOOR_MAP_PATHS_SUCCESS: 'GET_OUTDOOR_MAP_PATHS_SUCCESS',
    GET_MAP_PATHS: 'GET_MAP_PATHS',
    GET_MAP_PATHS_FAIL: 'GET_MAP_PATHS_FAIL',
    GET_MAP_PATHS_SUCCESS: 'GET_MAP_PATHS_SUCCESS',
    GET_AREA_OF_INTEREST: 'GET_AREA_OF_INTEREST',
    GET_AREA_OF_INTEREST_FAIL: 'GET_AREA_OF_INTEREST_FAIL',
    GET_AREA_OF_INTEREST_SUCCESS: 'GET_AREA_OF_INTEREST_SUCCESS',
    SAVE_MAP_POINTS: 'SAVE_MAP_POINTS',
    SAVE_MAP_POINTS_FAIL: 'SAVE_MAP_POINTS_FAIL',
    SAVE_MAP_POINTS_SUCCESS: 'SAVE_MAP_POINTS_SUCCESS',
    GET_MAP_POINTS: 'GET_MAP_POINTS',
    GET_MAP_POINTS_FAIL: 'GET_MAP_POINTS_FAIL',
    GET_MAP_POINTS_SUCCESS: 'GET_MAP_POINTS_SUCCESS',
    GET_OUTDOOR_MAP_POINTS: 'GET_OUTDOOR_MAP_POINTS',
    GET_OUTDOOR_MAP_POINTS_FAIL: 'GET_OUTDOOR_MAP_POINTS_FAIL',
    GET_OUTDOOR_MAP_POINTS_SUCCESS: 'GET_OUTDOOR_MAP_POINTS_SUCCESS',
    GET_MAP_AREAS: 'GET_MAP_AREAS',
    GET_MAP_AREAS_FAIL: 'GET_MAP_AREAS_FAIL',
    GET_MAP_AREAS_SUCCESS: 'GET_MAP_AREAS_SUCCESS',
    GET_MAP_AREA_DETAILS: 'GET_MAP_AREA_DETAILS',
    GET_MAP_AREA_DETAILS_FAIL: 'GET_MAP_AREA_DETAILS_FAIL',
    GET_MAP_AREA_DETAILS_SUCCESS: 'GET_MAP_AREA_DETAILS_SUCCESS',
    CREATE_MAP_FIGURE: 'CREATE_MAP_FIGURE',
    CREATE_MAP_FIGURE_SUCCESS: 'CREATE_MAP_FIGURE_SUCCESS',
    CREATE_MAP_FIGURE_FAIL: 'CREATE_MAP_FIGURE_FAIL',
    CREATE_OUTDOOR_PATH: 'CREATE_OUTDOOR_PATH',
    CREATE_OUTDOOR_PATH_SUCCESS: 'CREATE_OUTDOOR_PATH_SUCCESS',
    CREATE_PATH: 'CREATE_PATH',
    CREATE_PATH_SUCCESS: 'CREATE_PATH_SUCCESS',
    CREATE_PATH_FAIL: 'CREATE_PATH_FAIL',
    CREATE_POINT: 'CREATE_POINT',
    CREATE_POINT_SUCCESS: 'CREATE_POINT_SUCCESS',
    CREATE_POINT_FAIL: 'CREATE_POINT_FAIL',
    CREATE_POINT_WITHPOSITION: 'CREATE_POINT_WITHPOSITION',
    CREATE_POINT__WITHPOSITION_SUCCESS: 'CREATE_POINT__WITHPOSITION_SUCCESS',
    CREATE_POINT__WITHPOSITION_FAIL: 'CREATE_POINT__WITHPOSITION_FAIL',
    UPDATE_POINT: 'UPDATE_POINT',
    UPDATE_POINT_SUCCESS: 'UPDATE_POINT_SUCCESS',
    UPDATE_POINT_FAIL: 'UPDATE_POINT_FAIL',
    UPDATE_PATH: 'UPDATE_PATH',
    UPDATE_PATH_SUCCESS: 'UPDATE_PATH_SUCCESS',
    UPDATE_PATH_FAIL: 'UPDATE_PATH_FAIL',
    DELETE_POINT: 'DELETE_POINT',
    DELETE_POINT_SUCCESS: 'DELETE_POINT_SUCCESS',
    DELETE_POINT_FAIL: 'DELETE_POINT_FAIL',
    DELETE_PATH: 'DELETE_PATH',
    DELETE_PATH_SUCCESS: 'DELETE_PATH_SUCCESS',
    DELETE_PATH_FAIL: 'DELETE_PATH_FAIL',
    GET_LOCATION: 'GET_LOCATION',
    CREATE_OUTDOOR_AREA: 'CREATE_OUTDOOR_AREA',
    CREATE_OUTDOOR_AREA_SUCCESS: 'CREATE_OUTDOOR_AREA_SUCCESS',
    CREATE_OUTDOOR_POINT: 'CREATE_OUTDOOR_POINT',
    CREATE_OUTDOOR_POINT_SUCCESS: 'CREATE_OUTDOOR_POINT_SUCCESS',
    GET_OUTDOOR_AREA: 'GET_OUTDOOR_AREA',
    GET_OUTDOOR_AREA_SUCCESS: 'GET_OUTDOOR_AREA_SUCCESS',
    UPDATE_OUTDOOR_POINT: 'UPDATE_OUTDOOR_POINT',
    UPDATE_OUTDOOR_POINT_SUCCESS: 'UPDATE_OUTDOOR_POINT_SUCCESS',
    DELETE_OUTDOOR_POINT: 'DELETE_OUTDOOR_POINT',
    DELETE_OUTDOOR_POINT_SUCCESS: 'DELETE_OUTDOOR_POINT_SUCCESS',
    DELETE_OUTDOOR_PATH: 'DELETE_OUTDOOR_PATH',
    DELETE_OUTDOOR_PATH_SUCCESS: 'DELETE_OUTDOOR_PATH_SUCCESS',
    UPDATE_OUTDOOR_POINTS: 'UPDATE_OUTDOOR_POINTS',
    UPDATE_OUTDOOR_POINTS_SUCCESS: 'UPDATE_OUTDOOR_POINTS_SUCCESS',
    GET_SHARPNESS: 'GET_SHARPNESS',
    GET_SHARPNESS_SUCCESS: 'GET_SHARPNESS_SUCCESS',
    GET_MAP_ANALYSIS_STATUS: 'GET_MAP_ANALYSIS_STATUS',
    GET_MAP_ANALYSIS_STATUS_SUCCESS: 'GET_MAP_ANALYSIS_STATUS_SUCCESS',
    UPDATE_SHARPNESS: 'UPDATE_SHARPNESS',
    UPDATE_SHARPNESS_SUCCESS: 'UPDATE_SHARPNESS_SUCCESS',
    UPDATE_IMAGE: 'UPDATE_IMAGE',
    UPDATE_IMAGE_SUCCESS: 'UPDATE_IMAGE_SUCCESS',
    RESET_IMAGE: 'RESET_IMAGE',
    RESET_IMAGE_SUCCESS: 'RESET_IMAGE_SUCCESS',
    GET_SAFE_ZONES: 'GET_SAFE_ZONES',
    GET_SAFE_ZONES_SUCCESS: 'GET_SAFE_ZONES_SUCCESS',
    CREATE_SAFE_ZONES: 'CREATE_SAFE_ZONES',
    CREATE_SAFE_ZONES_SUCCESS: 'CREATE_SAFE_ZONES_SUCCESS',
    GET_RESTRICTED_ZONES: 'GET_RESTRICTED_ZONES',
    GET_RESTRICTED_ZONES_SUCCESS: 'GET_RESTRICTED_ZONES_SUCCESS',
    CREATE_RESTRICTED_ZONES: 'CREATE_RESTRICTED_ZONES',
    CREATE_RESTRICTED_ZONES_SUCCESS: 'CREATE_RESTRICTED_ZONES_SUCCESS',
    GET_MAP_IMAGE: 'GET_MAP_IMAGE',
    GET_MAP_IMAGE_SUCCESS: 'GET_MAP_IMAGE_SUCCESS',
    GET_MAP_IMAGE_FAIL: 'GET_MAP_IMAGE_FAIL',
    GET_RECORDING: 'GET_RECORDING',
    GET_RECORDING_SUCCESS: 'GET_RECORDING_SUCCESS',
    START_RECORDING: 'START_RECORDING',
    START_RECORDING_SUCCESS: 'START_RECORDING_SUCCESS',
    STOP_RECORDING: 'STOP_RECORDING',
    STOP_RECORDING_SUCCESS: 'STOP_RECORDING_SUCCESS',
    UPDATE_ORIGIN_POINT: 'UPDATE_ORIGIN_POINT',
    UPDATE_ORIGIN_POINT_FAIL: 'UPDATE_ORIGIN_POINT_FAIL',
    UPDATE_ORIGIN_POINT_SUCCESS: 'UPDATE_ORIGIN_POINT_SUCCESS',
    CREATE_AREA_ZONES: 'CREATE_AREA_ZONES',
    CREATE_AREA_ZONES_SUCCESS: 'CREATE_AREA_ZONES_SUCCESS',
    CREATE_AREA_ZONES_FAIL: 'CREATE_AREA_ZONES_FAIL',
    GET_AREA_ZONES: 'GET_AREA_ZONES',
    GET_AREA_ZONES_SUCCESS: 'GET_AREA_ZONES_SUCCESS',
    GET_AREA_ZONES_FAIL: 'GET_AREA_ZONES_FAIL',
    GET_ZONE_DIMENSIONS: 'GET_ZONE_DIMENSIONS',
    UPDATE_AREA_ZONES: 'UPDATE_AREA_ZONES',
    UPDATE_AREA_ZONES_SUCCESS: 'UPDATE_AREA_ZONES_SUCCESS',
    UPDATE_AREA_ZONES_FAIL: 'UPDATE_AREA_ZONES_FAIL',
    DELETE_AREA_ZONES: 'DELETE_AREA_ZONES',
    DELETE_AREA_ZONES_SUCCESS: 'DELETE_AREA_ZONES_SUCCESS',
    DELETE_AREA_ZONES_FAIL: 'DELETE_AREA_ZONES_FAIL',
    GET_MAP_STATIONS: 'GET_MAP_STATIONS',
    GET_MAP_STATIONS_SUCCESS: 'GET_MAP_STATIONS_SUCCESS',
    GET_MAP_STATIONS_FAIL: 'GET_MAP_STATIONS_FAIL',
    CREATE_MAP_STATIONS: 'CREATE_MAP_STATIONS',
    CREATE_MAP_STATIONS_SUCCESS: 'CREATE_MAP_STATIONS_SUCCESS',
    CREATE_MAP_STATIONS_FAIL: 'CREATE_MAP_STATIONS_FAIL',
    CREATE_TRAVERSAL_STATION: 'CREATE_TRAVERSAL_STATION',
    CREATE_TRAVERSAL_STATION_SUCCESS: 'CREATE_TRAVERSAL_STATION_SUCCESS',
    CREATE_TRAVERSAL_STATION_FAIL: 'CREATE_TRAVERSAL_STATION_FAIL',
    UPDATE_MAP_STATIONS: 'UPDATE_MAP_STATIONS',
    UPDATE_MAP_STATIONS_SUCCESS: 'UPDATE_MAP_STATIONS_SUCCESS',
    UPDATE_MAP_STATIONS_FAIL: 'UPDATE_MAP_STATIONS_FAIL',
    DELETE_MAP_STATIONS: 'DELETE_MAP_STATIONS',
    DELETE_MAP_STATIONS_SUCCESS: 'DELETE_MAP_STATIONS_SUCCESS',
    DELETE_MAP_STATIONS_FAIL: 'DELETE_MAP_STATIONS_FAIL',
    GET_PATHS: 'GET_PATHS',
    GET_PATHS_SUCCESS: 'GET_PATHS_SUCCESS',
    GET_PATHS_FAIL: 'GET_PATHS_FAIL',
    GET_ZONES_PATH: 'GET_ZONES_PATH',
    GET_ZONES_PATH_SUCCESS: 'GET_ZONES_PATH_SUCCESS',
    GET_ZONES_PATH_FAIL: 'GET_ZONES_PATH_FAIL',
    CREATE_ZONE_PATH: 'CREATE_ZONE_PATH',
    CREATE_ZONE_PATH_SUCCESS: 'CREATE_ZONE_PATH_SUCCESS',
    CREATE_ZONE_PATH_FAIL: 'CREATE_ZONE_PATH_FAIL',
    UPDATE_ZONE_PATH: 'UPDATE_ZONE_PATH',
    UPDATE_ZONE_PATH_SUCCESS: 'UPDATE_ZONE_PATH_SUCCESS',
    UPDATE_ZONE_PATH_FAIL: 'UPDATE_ZONE_PATH_FAIL',
    DELETE_ZONE_PATH: 'DELETE_ZONE_PATH',
    DELETE_ZONE_PATH_SUCCESS: 'DELETE_ZONE_PATH_SUCCESS',
    DELETE_ZONE_PATH_FAIL: 'DELETE_ZONE_PATH_FAIL',
    UPDATE_MAP_ROBOT: 'UPDATE_MAP_ROBOT',
    UPDATE_MAP_ROBOT_SUCCESS: 'UPDATE_MAP_ROBOT_SUCCESS',
    UPDATE_MAP_ROBOT_FAIL: 'UPDATE_MAP_ROBOT_FAIL',
    RESET_PATHS_AND_POINTS: 'RESET_PATHS_AND_POINTS',
    RESET_PATHS_AND_POINTS_SUCCESS: 'RESET_PATHS_AND_POINTS_SUCCESS',
    RESET_PATHS_AND_POINTS_FAIL: 'RESET_PATHS_AND_POINTS_FAIL',
    RESET_ZONES: 'RESET_ZONES',
    RESET_ZONES_SUCCESS: 'RESET_ZONES_SUCCESS',
    RESET_ZONES_FAIL: 'RESET_ZONES_FAIL',
    GET_AREA_SETTINGS: 'GET_AREA_SETTINGS',
    GET_AREA_SETTINGS_SUCCESS: 'GET_AREA_SETTINGS_SUCCESS',
    GET_AREA_SETTINGS_FAIL: 'GET_AREA_SETTINGS_FAIL',
    UPDATE_AREA_SETTINGS: 'UPDATE_AREA_SETTINGS',
    UPDATE_AREA_SETTINGS_SUCCESS: 'UPDATE_AREA_SETTINGS_SUCCESS',
    UPDATE_AREA_SETTINGS_FAIL: 'UPDATE_AREA_SETTINGS_FAIL',
    CAN_REDO: 'CAN_REDO',
    CAN_REDO_SUCCESS: 'CAN_REDO_SUCCESS',
    CAN_REDO_FAIL: 'CAN_REDO_FAIL',
    CAN_UNDO: 'CAN_UNDO',
    CAN_UNDO_SUCCESS: 'CAN_UNDO_SUCCESS',
    CAN_UNDO_FAIL: 'CAN_UNDO_FAIL',
    UNDO_REDO: 'UNDO_REDO',
    UNDO_REDO_FAIL: 'UNDO_REDO_FAIL',
    UNDO_REDO_SUCCESS: 'UNDO_REDO_SUCCESS',
}

export function getAreaMapSettings({ uuid }) {
    return {
        type: actionTypes.GET_AREA_SETTINGS,
        payload: {
            request: {
                url: `api/me/indoors/area/${uuid}/settings/`,
                method: 'GET',
            },
        },
    }
}

export function updateAreaMapSettings({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_AREA_SETTINGS,
        payload: {
            request: {
                url: `api/me/indoors/area/${uuid}/settings/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getAreaZonesAll(uuid) {
    return {
        type: actionTypes.GET_AREA_ZONES,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/zones/`,
                method: 'GET',
            },
        },
    }
}

export function getAreaZones({ uuid, type }) {
    return {
        type: actionTypes.GET_AREA_ZONES,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/zones/${type}/`,
                method: 'GET',
                type,
            },
        },
    }
}

export function createAreaZones({ uuid, data }) {
    return {
        type: actionTypes.CREATE_AREA_ZONES,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/zones/${data.type ? data.type : data.zone_type}/`,
                method: 'POST',
                data: data.type ? data.zones : data,
                type: data.type,
            },
        },
    }
}

export function updateAreaZone({ data, type }) {
    return {
        type: actionTypes.UPDATE_AREA_ZONES,
        payload: {
            request: {
                url: `api/zones/${type}/${data.uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteAreaZone({ type, uuid }) {
    return {
        type: actionTypes.DELETE_AREA_ZONES,
        payload: {
            request: {
                url: `api/zones/${type}/${uuid}/`,
                method: 'DELETE',
                type,
                uuid,
            },
        },
    }
}

export function getZoneDimensions({ data, uuid }) {
    return {
        type: actionTypes.GET_ZONE_DIMENSIONS,
        payload: {
            request: {
                url: `/api/indoors/area/${uuid}/zone_dimensions/`,
                method: 'POST',
                data
            },
        },
    }
}

export function getRestrictedZones(uuid) {
    return {
        type: actionTypes.GET_RESTRICTED_ZONES,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/obstacles/`,
                method: 'GET',
            },
        },
    }
}

export function createRestrictedZones({ uuid, data }) {
    return {
        type: actionTypes.CREATE_RESTRICTED_ZONES,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/obstacles/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function resetImage(uuid) {
    return {
        type: actionTypes.UPDATE_IMAGE,
        payload: {
            request: {
                url: `indoors/area/${uuid}/reset_displayable_image/`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Content-Length': 0 },
                // data,
            },
        },
    }
}

export function resetPathsAndStations(uuid) {
    return {
        type: actionTypes.RESET_PATHS_AND_POINTS,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/stations/reset/`,
                method: 'POST',
            },
        },
    }
}

export function resetZones(uuid) {
    return {
        type: actionTypes.RESET_ZONES,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/zones/reset/`,
                method: 'POST',
            },
        },
    }
}

export function updateImage({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_IMAGE,
        payload: {
            request: {
                url: `indoors/area/${uuid}/update_displayable_image.png`,
                method: 'PATCH',
                headers: { 'content-type': 'application/json' },
                data,
            },
        },
    }
}

export function getAnalysisStatus(uuid) {
    return {
        type: actionTypes.GET_MAP_ANALYSIS_STATUS,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/map-analysis-status/`,
                method: 'GET',
            },
        },
    }
}

export function getSharpness(uuid) {
    return {
        type: actionTypes.GET_SHARPNESS,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/map-analysis-parameters/`,
                method: 'GET',
            },
        },
    }
}
export function updateSharpness({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_SHARPNESS,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/map-analysis-parameters/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getMapPoints(slug) {
    return {
        type: actionTypes.GET_MAP_POINTS,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/points/`,
                method: 'GET',
            },
        },
    }
}

export function getMapStations({ uuid }) {
    return {
        type: actionTypes.GET_MAP_STATIONS,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/stations/`,
                method: 'GET',
            },
        },
    }
}

export function createOutdoorPoint({ slug, data }) {
    return {
        type: actionTypes.CREATE_OUTDOOR_POINT,
        payload: {
            request: {
                url: `api/teams/${slug}/outdoor/points/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateOutdoorPoints({ slug, data }) {
    return {
        type: actionTypes.UPDATE_OUTDOOR_POINTS,
        payload: {
            request: {
                url: `api/teams/${slug}/outdoor/points/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getOutdoorMapPoints({ slug }) {
    return {
        type: actionTypes.GET_OUTDOOR_MAP_POINTS,
        payload: {
            request: {
                url: `api/teams/${slug}/outdoor/points/`,
                method: 'GET',
            },
        },
    }
}

export function getMapAreas(slug) {
    return {
        type: actionTypes.GET_MAP_AREAS,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/areas/`,
                method: 'GET',
            },
        },
    }
}

export function getMapAreaDetails(uuid) {
    return {
        type: actionTypes.GET_MAP_AREA_DETAILS,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function updateOriginPoint({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_ORIGIN_POINT,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getMapImageDetails(uuid) {
    return {
        type: actionTypes.GET_MAP_IMAGE,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/images/`,
                method: 'GET',
            },
        },
    }
}

export function getMapAreaOfInterest(uuid) {
    return {
        type: actionTypes.GET_AREA_OF_INTEREST,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/aoi/`,
                method: 'GET',
            },
        },
    }
}

export function getOutdoorMapPaths({ slug }) {
    return {
        type: actionTypes.GET_OUTDOOR_MAP_PATHS,
        payload: {
            request: {
                url: `api/teams/${slug}/outdoor/paths/`,
                method: 'GET',
            },
        },
    }
}

export function getMapPaths(slug) {
    return {
        type: actionTypes.GET_MAP_PATHS,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/paths/`,
                method: 'GET',
            },
        },
    }
}

export function getZonesPaths(uuid) {
    return {
        type: actionTypes.GET_PATHS,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/paths/`,
                method: 'GET',
            },
        },
    }
}

export function getZonesPath(uuid) {
    return {
        type: actionTypes.GET_ZONES_PATH,
        payload: {
            request: {
                url: `api/paths/${uuid}`,
                method: 'GET',
            },
        },
    }
}

export function createMapFigures({ slug, ...data }) {
    return {
        type: actionTypes.CREATE_MAP_FIGURE,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/area/figures/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function savePoints({ slug, data }) {
    return {
        type: actionTypes.SAVE_MAP_POINTS,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/points/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function outdoorFinalize(slug) {
    return {
        type: actionTypes.OUTDOOR_FINALIZE,
        payload: {
            request: {
                url: `api/teams/${slug}/outdoor/finalize/`,
                method: 'GET',
            },
        },
    }
}

// export function finalize(slug) {
//     return {
//         type: actionTypes.FINALIZE,
//         payload: {
//             request: {
//                 url: `api/teams/${slug}/indoors/finalize/`,
//                 method: 'GET',
//             },
//         },
//     }
// }

export function deleteOutdoorPath({ uuid }) {
    return {
        type: actionTypes.DELETE_OUTDOOR_PATH,
        payload: {
            request: {
                url: `/api/outdoors/paths/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function deletePath({ uuid }) {
    return {
        type: actionTypes.DELETE_PATH,
        payload: {
            request: {
                url: `/api/indoors/paths/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function deleteOutdoorPoint({ uuid }) {
    return {
        type: actionTypes.DELETE_OUTDOOR_POINT,
        payload: {
            request: {
                url: `/api/outdoors/points/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function deletePoint({ uuid }) {
    return {
        type: actionTypes.DELETE_POINT,
        payload: {
            request: {
                url: `/api/indoors/points/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function createOutdoorPath({ slug, data }) {
    return {
        type: actionTypes.CREATE_OUTDOOR_PATH,
        payload: {
            request: {
                url: `api/teams/${slug}/outdoor/paths/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createPath({ slug, data }) {
    return {
        type: actionTypes.CREATE_PATH,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/paths/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updatePath({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_PATH,
        payload: {
            request: {
                url: `api/indoors/paths/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function createZonesPath({ uuid, data }) {
    return {
        type: actionTypes.CREATE_ZONE_PATH,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/paths/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateZonesPath({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_ZONE_PATH,
        payload: {
            request: {
                url: `api/paths/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteZonesPath(uuid) {
    return {
        type: actionTypes.DELETE_ZONE_PATH,
        payload: {
            request: {
                url: `api/paths/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function createPoint({ slug, data }) {
    return {
        type: actionTypes.CREATE_POINT,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/points/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createPointWithPosition({ slug, data }) {
    return {
        type: actionTypes.CREATE_POINT_WITHPOSITION,
        payload: {
            request: {
                url: `api/teams/${slug}/indoors/points/from-position/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateOutdoorPoint({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_OUTDOOR_POINT,
        payload: {
            request: {
                url: `/api/outdoors/points/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function updatePoint({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_POINT,
        payload: {
            request: {
                url: `/api/indoors/points/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function createStation({ uuid, data }) {
    return {
        type: actionTypes.CREATE_MAP_STATIONS,
        payload: {
            request: {
                url: `/api/indoors/area/${uuid}/stations/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createTraversal({ uuid, data }) {
    return {
        type: actionTypes.CREATE_TRAVERSAL_STATION,
        payload: {
            request: {
                url: `/api/indoors/area/${uuid}/stations/traversal/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateStation({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_MAP_STATIONS,
        payload: {
            request: {
                url: `/api/stations/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function deleteStation(uuid) {
    return {
        type: actionTypes.DELETE_MAP_STATIONS,
        payload: {
            request: {
                url: `/api/stations/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function updateMapToRobot(uuid) {
    return {
        type: actionTypes.UPDATE_MAP_ROBOT,
        payload: {
            request: {
                url: `api/indoors/area/${uuid}/update-map/`,
                method: 'POST',
            },
        },
    }
}

export function saveOutdoorMapPointAndFinalize({ slug, data }) {
    return (dispatch) =>
        dispatch(updateOutdoorPoints({ slug, data })).then((response) => {
            if (response.error) {
                return Promise.resolve(response)
            }
            return dispatch(outdoorFinalize(slug)).then((res) => {
                if (res.error) return Promise.resolve(res)
                return Promise.resolve(true)
            })
        })
}

export function saveMapStationsAndFinalize({ slug, uuid, stations, devices }) {
    switch (true) {
        case stations.length === 0 && devices.length > 0:
            return (dispatch) =>
                dispatch(udpateDevices({ data: devices, uuid })).then((response) => {
                    if (response.error) {
                        return Promise.resolve(response)
                    }
                    dispatch(getDevices({ slug }))
                })
        case devices.length === 0 && stations.length > 0:
            return (dispatch) =>
                dispatch(createStation({ uuid, data: stations })).then((response) => {
                    if (response.error) {
                        return Promise.resolve(response)
                    }
                    dispatch(getMapStations({ uuid }))
                    dispatch(getZonesPaths(uuid))
                })
        case devices.length > 0 && stations.length > 0:
            return (dispatch) =>
                dispatch(createStation({ uuid, data: stations })).then((response) => {
                    if (response.error) {
                        return Promise.resolve(response)
                    }
                    dispatch(getMapStations({ uuid }))
                    dispatch(getZonesPaths(uuid))
                    return dispatch(udpateDevices({ data: devices, uuid })).then((res) => {
                        if (res.error) return Promise.resolve(res)
                        dispatch(getDevices({ slug }))
                    })
                })
        default:
            return
    }
}

export function getLocation(query) {
    return {
        type: actionTypes.GET_LOCATION,
        payload: {
            client: 'thirdParty',
            request: {
                url: `https://eu1.locationiq.com/v1/search.php?key=pk.a93a1fd3b5cbf9e8048fcb1fad2ac63f&q=${query}&format=json`,
                method: 'GET',
            },
        },
    }
}

export function getOutdoorArea({ slug }) {
    return {
        type: actionTypes.GET_OUTDOOR_AREA,
        payload: {
            request: {
                url: `/api/teams/${slug}/outdoor/`,
            },
        },
    }
}

export function createOutdoorArea({ slug, ...data }) {
    return {
        type: actionTypes.CREATE_OUTDOOR_AREA,
        payload: {
            request: {
                url: `/api/teams/${slug}/outdoor/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function startRecording({ uuid, ...data }) {
    return {
        type: actionTypes.START_RECORDING,
        payload: {
            request: {
                url: `/api/indoors/points/${uuid}/docking-routine/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function stopRecording({ uuid, ...data }) {
    return {
        type: actionTypes.STOP_RECORDING,
        payload: {
            request: {
                url: `/api/indoors/points/${uuid}/docking-routine/`,
                method: 'PUT',
                data,
            },
        },
    }
}

export function getFinalizedRecording({ ...data }) {
    return {
        type: actionTypes.GET_RECORDING,
        payload: {
            request: {
                url: `/api/indoors/points/${data.uuid}/docking-routine/`,
                method: 'GET',
            },
        },
    }
}

export function canUndo(uuid) {
    return {
        type: actionTypes.CAN_UNDO,
        payload: {
            request: {
                url: `/api/indoors/area/${uuid}/undo/`,
                method: 'GET',
            },
        },
    }
}

export function canRedo(uuid) {
    return {
        type: actionTypes.CAN_REDO,
        payload: {
            request: {
                url: `/api/indoors/area/${uuid}/redo/`,
                method: 'GET',
            },
        },
    }
}

export function doUndoRedo(uuid, text) {
    return {
        type: actionTypes.UNDO_REDO,
        payload: {
            request: {
                url: `/api/indoors/area/${uuid}/${text}/`,
                method: 'POST',
            },
        },
    }
}
